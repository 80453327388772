import { Button, CircularProgress, Switch, Tooltip } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import { colors } from '../../../assets/utils/constants';
import {
    useActivateSubscriptionPackageMutation,
    useDeactivateSubscriptionPackageMutation,
} from '../../../api/subscriptionPackages/subscriptionPackagesAPI';
import { setSubscriptionPackages } from '../../../states/features/subscriptionPackages/subscriptionPackagesSlice';

const ActivateDeactivateSubscriptionPackage = ({ subscriptionPackage }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();

    // ############# RTK ##################
    const [
        activateAction,
        {
            isLoading: activateLoading,
            isSuccess: activateSuccess,
            isError: activateIsError,
            error: activateError,
            data: activateData,
        },
    ] = useActivateSubscriptionPackageMutation();
    const [
        deactivateAction,
        {
            isLoading: deactivateLoading,
            isSuccess: deactivateSuccess,
            isError: deactivateIsError,
            error: deactivateError,
            data: deactivateData,
        },
    ] = useDeactivateSubscriptionPackageMutation();

    // ############# useEffect ##################
    // => Activate res
    // ############# useEffect ##################
    const rtkActions = (data) => {
        dispatch(setSubscriptionPackages(data));
    };
    RTK.useRTKResponse(
        activateSuccess,
        activateIsError,
        activateError,
        activateData,
        rtkActions,
    );
    RTK.useRTKResponse(
        deactivateSuccess,
        deactivateIsError,
        deactivateError,
        deactivateData,
        rtkActions,
    );
    return (
        <>
            <Tooltip
                arrow
                placement="top"
                title={subscriptionPackage?.status ? 'Deactivate' : 'Activate'}
            >
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={
                        activateLoading || deactivateLoading ? true : false
                    }
                    sx={{
                        padding: 0,
                        mr: 1,
                        opacity: 0.8,
                        border: `1px solid ${colors.info} !important`,
                    }}
                    onClick={() =>
                        subscriptionPackage?.status
                            ? deactivateAction(subscriptionPackage?.id)
                            : activateAction(subscriptionPackage?.id)
                    }
                >
                    {activateLoading || deactivateLoading ? (
                        <>
                            <CircularProgress
                                size={20}
                                color="primary"
                                sx={{
                                    my: 0.25,
                                }}
                            />
                        </>
                    ) : (
                        <Switch
                            size="small"
                            color={
                                subscriptionPackage?.status
                                    ? 'success'
                                    : 'error'
                            }
                            checked={subscriptionPackage?.status ? true : false}
                        />
                    )}
                </Button>
            </Tooltip>
        </>
    );
};

export default ActivateDeactivateSubscriptionPackage;
