import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { borderRadius, colors } from '../../assets/utils/constants';

const Card = styled(Box)({
    borderRadius: 15,
    background: colors.bgColor2,
    border: `1px solid ${colors.bgColor4}`,
});

const CardHeader = styled(Box)({
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'paddingTop': 12,
    'paddingBottom': 12,
    'paddingLeft': 15,
    'paddingRight': 15,
    'position': 'relative !important',
    '.head': {
        'fontSize': 16,
        'color': colors.primary,
        'fontWeight': 'bold',
        'opacity': 0.8,
        '::before': {
            content: "''",
            width: 90,
            height: 4,
            background: colors.bgColor5,
            position: 'absolute',
            bottom: 0,
        },
    },
});

const CardBody = styled(Box)({
    padding: 15,
    overflow: 'auto',
});
const CardFooter = styled(Box)({
    overflow: 'auto',
});

const CustomCard2 = ({
    action,
    head,
    height,
    borderBottom,
    bodyPadding,
    bRadius,
    footer,
    children,
}) => {
    return (
        <React.Fragment>
            <Card
                sx={{
                    borderRadius: bRadius ? bRadius : borderRadius.br1,
                    borderBottom: borderBottom
                        ? `15px solid ${colors.bgColor4}`
                        : `1px solid ${colors.bgColor4}`,
                    position: 'relative',
                }}
            >
                {head && (
                    <CardHeader>
                        <Typography className="head">{head}</Typography>
                        <Box sx={{ position: 'relative' }}>{action}</Box>
                    </CardHeader>
                )}
                <CardBody
                    sx={{
                        position: 'relative',
                        height: height,
                        padding: bodyPadding && bodyPadding,
                    }}
                >
                    {children}
                </CardBody>
                {footer && <CardFooter>{footer}</CardFooter>}
            </Card>
        </React.Fragment>
    );
};

export default CustomCard2;
