import React, { useState } from 'react';
import { Box, Button, Chip, Tooltip, Typography } from '@mui/material';
import { DeleteForever, Edit, TaskAltTwoTone } from '@mui/icons-material';
import { colors } from '../../assets/utils/constants';
import CustomDialog from '../Dialogies/CustomDialog';
import { numFormater } from '../../helpers/numFormater';
import DeleteSubscriptionPackage from '../../pages/subscriptionPackages/CRUD/DeleteSubscriptionPackage';
import UpdateSubscriptionPackage from '../../pages/subscriptionPackages/CRUD/UpdateSubscriptionPackage';
import ActivateDeactivateSubscriptionPackage from '../../pages/subscriptionPackages/CRUD/ActivateDeactivateSubscriptionPackage';

const TalentTapPackageWidget = ({ subscriptionPackage }) => {
    // ############## Comp State ##################
    const [dialogyOpen, setDialogyOpen] = useState(false);
    const [actionType, setActionType] = useState('');

    // ############## FUNC #####################
    // => Handle docs open
    const handleDialogOpen = (type) => {
        setActionType(type);
        setDialogyOpen(true);
    };
    // => Dialogy closer
    const handleDialogClose = () => {
        setDialogyOpen(false);
    };

    const packIncl = [
        {
            id: 1,
            item: 'One shop advertisement',
        },
        {
            id: 2,
            item: '10 product showcase per shop',
        },
    ];
    return (
        <>
            {/* ################# Dialogy ################## */}
            <CustomDialog
                openDialog={dialogyOpen}
                handleDialogClose={handleDialogClose}
                head={
                    actionType === 'update'
                        ? 'Update subscription package'
                        : actionType === 'delete'
                        ? 'Confirm subscription package deletion'
                        : ''
                }
            >
                {actionType === 'update' ? (
                    <UpdateSubscriptionPackage
                        handleDialogClose={handleDialogClose}
                        subscriptionPackage={subscriptionPackage}
                    />
                ) : actionType === 'delete' ? (
                    <DeleteSubscriptionPackage
                        handleDialogClose={handleDialogClose}
                        subscriptionPackage={subscriptionPackage}
                    />
                ) : (
                    ''
                )}
            </CustomDialog>

            <Box
                sx={{
                    display: 'flex',
                    position: 'relative',
                    justifyContent: 'center',
                }}
            >
                <Box
                    sx={{
                        'width': 260,
                        '& .action-btn': {
                            transition: `.3s ease-in-out all`,
                        },
                        '& .price-con': {
                            transition: `.3s ease-in-out all`,
                        },
                        ':hover': {
                            '& .action-btn': {
                                bottom: 48,
                                transition: `.3s ease-in-out all`,
                            },
                            '& .price-con': {
                                transition: `.3s ease-in-out all`,
                                boxShadow: `1px 1px 5px 2px ${colors.bgColor5}`,
                                background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor4})`,
                            },
                        },
                    }}
                >
                    <Box
                        sx={{
                            height: 320,
                            background: `linear-gradient(180deg, ${colors.secondary}, ${colors.bgColor3})`,
                            border: `5px solid ${colors.secondary}`,
                            overflow: 'hidden',
                            borderRadius: 3,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Box
                            sx={{
                                background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                                borderBottomRightRadius: 10,
                                borderBottomLeftRadius: 10,
                                color: colors.info,
                                boxShadow: `15px 8px 10px ${colors.bgColor5}`,
                                position: 'relative',
                                transform: `translate(0px, 5px) rotate(2.5deg)`,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase',
                                    opacity: 0.8,
                                    p: 1,
                                }}
                            >
                                {subscriptionPackage.name}
                            </Typography>
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: -30,
                                }}
                            >
                                <Chip
                                    label={
                                        subscriptionPackage.status === 1
                                            ? 'Active'
                                            : 'In-active'
                                    }
                                    color={
                                        subscriptionPackage.status === 1
                                            ? 'success'
                                            : 'warning'
                                    }
                                    size="small"
                                    sx={{ fontWeight: 'bold', opacity: 0.8 }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ p: 2 }}>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    borderBottom: `2px dotted ${colors.info}`,
                                    my: 3,
                                    opacity: 0.8,
                                    textAlign: 'center',
                                }}
                            >
                                The package Include
                            </Typography>
                            <Box>
                                {packIncl.map((item) => (
                                    <Typography
                                        key={item.id}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            fontSize: 15,
                                            mb: 1,
                                        }}
                                    >
                                        <TaskAltTwoTone
                                            sx={{
                                                fontSize: 18,
                                                mr: 0.5,
                                                color: colors.success,
                                            }}
                                        />
                                        {item.item}
                                    </Typography>
                                ))}
                            </Box>
                        </Box>
                        <Box
                            className="action-btn"
                            sx={{
                                py: 0.5,
                                display: 'flex',
                                alignItems: 'flex-start',
                                justifyContent: 'space-between',
                                position: 'absolute',
                                bottom: 0,
                                right: 10,
                                left: 10,
                                px: 2,
                            }}
                        >
                            <ActivateDeactivateSubscriptionPackage
                                subscriptionPackage={subscriptionPackage}
                            />
                            <Tooltip title="Update plan" arrow placement="top">
                                <Button
                                    size="small"
                                    fullWidth
                                    variant="contained"
                                    sx={{ width: 10, opacity: 0.8 }}
                                    color="warning"
                                    onClick={() => {
                                        let type = 'update';
                                        handleDialogOpen(type);
                                    }}
                                >
                                    <Edit sx={{ fontSize: 18 }} />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Delete plan" arrow placement="top">
                                <Button
                                    size="small"
                                    fullWidth
                                    variant="contained"
                                    sx={{ width: 10, opacity: 0.8 }}
                                    color="error"
                                    onClick={() => {
                                        let type = 'delete';
                                        handleDialogOpen(type);
                                    }}
                                >
                                    <DeleteForever sx={{ fontSize: 18 }} />
                                </Button>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box
                        className="price-con"
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                            justifyContent: 'center',
                            width: 280,
                            position: 'absolute',
                            bottom: 0,
                            borderTopRightRadius: 55,
                            border: `2px solid ${colors.secondary}`,
                            boxShadow: `1px 1px 3px 2px ${colors.bgColor4}`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 18,
                                textAlign: 'center',
                                p: 1,
                                fontWeight: 'bold',
                                color: colors.info,
                            }}
                        >
                            {numFormater(subscriptionPackage.price)}
                            <sup
                                style={{
                                    fontSize: 11,
                                    color: colors.success,
                                }}
                            >
                                TZS
                            </sup>
                        </Typography>
                        <Typography> / </Typography>
                        <Typography
                            sx={{
                                fontSize: 18,
                                textAlign: 'center',
                                p: 1,
                                fontWeight: 'bold',
                                color: colors.info,
                            }}
                        >
                            {subscriptionPackage.duration} Days
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default TalentTapPackageWidget;
