import { Box, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import { Link } from 'react-router-dom';

const TodayActivitieWidget = ({ activity }) => {
    return (
        <>
            <Link to={activity.url}>
                <Box
                    sx={{
                        'height': 50,
                        'width': '100%',
                        'background': `linear-gradient(90deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                        'display': 'flex',
                        'justifyContent': 'space-between',
                        'alignItems': 'center',
                        'borderRadius': 2,
                        'borderRight': `2px solid ${colors.secondary}`,
                        'border': `1px solid ${colors.secondary}`,
                        '& .total': {
                            transition: `.3s all ease-in-out`,
                        },
                        '& .total': {
                            transition: `.3s all ease-in-out`,
                            border: `2px dotted ${colors.secondary}`,
                        },
                        ':hover': {
                            '& .total': {
                                transition: `.3s all ease-in-out`,
                                border: `2px dotted ${colors.warning}`,
                                // background: `linear-gradient(90deg, ${colors.warning}, ${colors.bgColor2})`,
                            },
                        },
                    }}
                >
                    <Box
                        className="total"
                        sx={{
                            background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                            height: 50,
                            width: 50,
                            borderRadius: 2,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 15,
                                fontWeight: 'bold',
                                color: colors.warning,
                            }}
                        >
                            {activity.total}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 12,
                                fontWeight: 'bold',
                                color: colors.info,
                                px: 1,
                            }}
                        >
                            {activity.title}
                        </Typography>
                    </Box>
                </Box>
            </Link>
        </>
    );
};

export default TodayActivitieWidget;
