import React from 'react';
import { LocationOn } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Chip,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import { colors } from '../../assets/utils/constants';
import CustomCard2 from '../../components/cards/CustomCard2';
import PageLayout from '../layouts/PageLayout';
import OccupationCard from '../../components/cards/OccupationCard';

const StudentDetails = () => {
    return (
        <>
            <PageLayout head={'Student account details'} noSidebar>
                <Grid container justifyContent={'center'} spacing={2}>
                    <Grid item sm={5}>
                        <Box
                            sx={{
                                background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor4})`,
                                overflow: 'hidden',
                                borderRadius: 2,
                            }}
                        >
                            <Box
                                sx={{
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    height: 230,
                                }}
                            >
                                <Box
                                    sx={{
                                        height: 150,
                                        background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary})`,
                                        width: '100%',
                                    }}
                                ></Box>
                                <Box
                                    sx={{
                                        height: 100,
                                        width: '100%',
                                    }}
                                ></Box>
                                <Box
                                    sx={{
                                        background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary})`,
                                        position: 'absolute',
                                        borderRadius: 50,
                                        p: 1,
                                    }}
                                >
                                    <Avatar sx={{ height: 150, width: 150 }} />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            right: 20,
                                            bottom: -60,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Chip
                                            label="Active"
                                            color="success"
                                            size="small"
                                        />
                                        <Typography
                                            sx={{
                                                fontWeight: 'bold',
                                                color: colors.info,
                                            }}
                                        >
                                            IMC/BIT/16/90798
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    'width': '100%',
                                    'px': 2,
                                    '.head': {
                                        fontWeight: 'bold',
                                        color: colors.primary,
                                        fontSize: 16,
                                        mb: 1,
                                        opacity: 0.8,
                                    },
                                    '.info-box': {
                                        'width': '100%',
                                        'p': 1,
                                        'borderRadius': 2,
                                        'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                        '.title': {
                                            fontSize: 13,
                                            fontWeight: 'bold',
                                            opacity: 0.9,
                                        },
                                    },
                                }}
                            >
                                <Box sx={{ mt: 8 }}>
                                    <Typography className="head">
                                        Personal infos
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6}>
                                            <Box
                                                className="info-box"
                                                sx={{
                                                    mr: 1,
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Typography className="title">
                                                    Full name
                                                </Typography>
                                                <Typography
                                                    noWrap
                                                    sx={{ width: '100%' }}
                                                >
                                                    Issac Adam Juma
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <Box
                                                className="info-box"
                                                sx={{
                                                    ml: 1,
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Typography className="title">
                                                    Address
                                                </Typography>
                                                <Typography
                                                    noWrap
                                                    sx={{ width: '100%' }}
                                                >
                                                    Dar-es-saalam, Tabata
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <Box
                                                className="info-box"
                                                sx={{
                                                    mr: 1,
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Typography className="title">
                                                    Gender
                                                </Typography>
                                                <Typography>Male</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <Box
                                                className="info-box"
                                                sx={{
                                                    ml: 1,
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <Typography
                                                    className="title"
                                                    noWrap
                                                >
                                                    Birth date
                                                </Typography>
                                                <Typography
                                                    noWrap
                                                    sx={{ width: '100%' }}
                                                >
                                                    25, Dec 1996
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box sx={{ mt: 6 }}>
                                    <Typography className="head">
                                        Contact infos
                                    </Typography>
                                    <Box sx={{ display: 'flex' }}>
                                        <Box
                                            className="info-box"
                                            sx={{
                                                mr: 1,
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Typography className="title">
                                                Phone number
                                            </Typography>
                                            <Typography
                                                noWrap
                                                sx={{ width: '100%' }}
                                            >
                                                +255 743 967 569
                                            </Typography>
                                        </Box>
                                        <Box
                                            className="info-box"
                                            sx={{
                                                ml: 1,
                                                flexDirection: 'column',
                                            }}
                                        >
                                            <Typography className="title">
                                                Email address
                                            </Typography>
                                            <Typography
                                                noWrap
                                                sx={{ width: '100%' }}
                                            >
                                                issac@gmail.com
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{ my: 3, textAlign: 'center' }}></Box>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={7}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <CustomCard2 head={'Institution details'}>
                                    <Box>
                                        <CustomCard2
                                            head={'Attended Institution'}
                                        >
                                            <ListItem
                                                alignItems="flex-start"
                                                sx={{ p: 0 }}
                                            >
                                                <ListItemAvatar>
                                                    <Avatar
                                                        alt="Business Op"
                                                        src=""
                                                        variant="square"
                                                        sx={{ borderRadius: 2 }}
                                                    />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    sx={{
                                                        '.MuiListItemText-primary': {
                                                            fontSize: 14,
                                                            fontWeight: 'bold',
                                                        },
                                                    }}
                                                    primaryTypographyProps={{
                                                        color: colors.primary,
                                                    }}
                                                    primary={"VETA Chang'ombe"}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{
                                                                    display:
                                                                        'flex',
                                                                    alignItems:
                                                                        'center',
                                                                }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >
                                                                <LocationOn
                                                                    sx={{
                                                                        fontSize: 14,
                                                                        color:
                                                                            colors.secondary,
                                                                    }}
                                                                />
                                                                {
                                                                    'Dar-es-saalam'
                                                                }
                                                            </Typography>
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        </CustomCard2>
                                    </Box>
                                </CustomCard2>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomCard2
                                    head={'Program | Occupation | Section'}
                                    height={347}
                                >
                                    <OccupationCard />
                                </CustomCard2>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <CustomCard2
                                    head={'Attended trainings'}
                                    height={347}
                                ></CustomCard2>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default StudentDetails;
