import React from 'react';
import PageLayout from '../layouts/PageLayout';
import { Box, Grid } from '@mui/material';
import RevenueFilter from '../../components/filters/RevenueFilter';
import CustomCard2 from '../../components/cards/CustomCard2';
import RevenueCard2 from '../../components/dash/RevenueCard2';

const Finance = () => {
    return (
        <>
            <PageLayout head="Finance managements" noSidebar>
                <Grid container spacing={1.5}>
                    <Grid item sm={12}>
                        <CustomCard2
                            head={'Revenue overview'}
                            borderBottom
                            action={
                                <Box sx={{ display: 'flex' }}>
                                    <RevenueFilter width={180} />
                                </Box>
                            }
                        >
                            <RevenueCard2 />
                        </CustomCard2>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default Finance;
