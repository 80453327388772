import { useEffect } from 'react';
import { errorNotify, successNotify } from '../helpers/notify';
import {
    getRTKErrorMessage,
    getRTKResponseData,
    getRTKSuccessMessage,
} from '../helpers/RTKHelpers';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetBlogsQuery } from '../api/blogs/blogsAPI';
import { setBlogs } from '../states/features/blogs/blogsSlice';
import { useLazyGetAllOrganizationsQuery } from '../api/organizations/organizationAPI';
import { useLazyGetAllSettingsQuery } from '../api/settings/settingAPI';
import { setSettings } from '../states/features/settings/settingsSlice';
import { setOrganizations } from '../states/features/organizations/organizationsSlice';
import { useLazyGetPromotionsCustomersQuery } from '../api/promotions/promotionsCustomersAPI';
import { setCustomers } from '../states/features/promotions/promotionCustomersSlice';
import { setPromotions } from '../states/features/promotions/promotionsSlice';
import { useLazyGetPromotionsQuery } from '../api/promotions/promotionsAPI';
import {
    useLazyGetAllEmployerJobsQuery,
    useLazyGetORGJobsQuery,
} from '../api/job/jobAPI';
import {
    setAllEmployerJobs,
    setOrganizationJobs,
} from '../states/features/jobs/jobsSlice';
import { disableMainFetch } from '../states/features/settings/settingsSlice';
import { settingsSelector } from '../states/features/selectors';
import { useLazyGetAllEmployersQuery } from '../api/employers/employersAPI';
import { setEmployers } from '../states/features/employers/employersSlice';
import { useLazyGetAllBeneficiariesQuery } from '../api/beneficiaries/beneficiaryAPI';
import { setBeneficiaries } from '../states/features/beneficiary/beneficiarySlice';
import { setShops } from '../states/features/shops/shopsSlice';
import { useLazyGetAllShopsQuery } from '../api/shops/shopAPI';
import { setStudents } from '../states/features/students/studentsSlice';
import { useLazyGetAllStudentsQuery } from '../api/students/studentsAPI';
import { useLazyGetAllTrainingsQuery } from '../api/practicalTrainings/practicalTrainingsAPI';
import { setPracticalTrainings } from '../states/features/practicalTraining/practicalTrainingsSlice';
import { useLazyGetAllPackagesQuery } from '../api/subscriptionPackages/subscriptionPackagesAPI';
import { setSubscriptionPackages } from '../states/features/subscriptionPackages/subscriptionPackagesSlice';

const useRTK = () => {
    const { isMainFetch } = useSelector(settingsSelector);

    // => RTK Response
    const useRTKResponse = (isSuccess, isError, error, data, actions) => {
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                if (isSuccess) {
                    actions && actions(getRTKResponseData(data));
                    setTimeout(() => {
                        successNotify(getRTKSuccessMessage(data));
                    }, 500);
                }
                if (isError) {
                    errorNotify(getRTKErrorMessage(error));
                }
            }
            return () => {
                isSubscribed = false;
            };
        }, [isSuccess, isError, error, data]);
    };

    // => RTK Fetch
    const useRTKFetch = (isSuccess, data, actions) => {
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                if (isSuccess) {
                    actions(getRTKResponseData(data));
                }
            }
            return () => {
                isSubscribed = false;
            };
        }, [isSuccess, data]);
    };

    /************************************************************
    # RTK main data 
    ************************************************************/
    const useRTKMainData = () => {
        // => CONST
        const dispatch = useDispatch();

        // ################# Blogs #################
        const [
            getBlogs,
            { isLoading: blogLoading, isSuccess, data: blogsData },
        ] = useLazyGetBlogsQuery();
        const rtkActions = (data) => {
            dispatch(setBlogs(data));
        };
        useRTKFetch(isSuccess, blogsData, rtkActions);
        // ################# Promotions #################
        const [
            getPromotions,
            {
                isLoading: promoLoading,
                isSuccess: promoSuccess,
                data: promoData,
            },
        ] = useLazyGetPromotionsQuery();
        const rtkPromoActions = (data) => {
            dispatch(setPromotions(data));
        };
        useRTKFetch(promoSuccess, promoData, rtkPromoActions);
        // ################# Organizations #################
        const [
            getAllOrganizations,
            { isLoading: orgLoading, isSuccess: orgSuccess, data: orgData },
        ] = useLazyGetAllOrganizationsQuery();
        const rtkORGActions = (data) => {
            dispatch(setOrganizations(data));
        };
        useRTKFetch(orgSuccess, orgData, rtkORGActions);
        // ################# Settings #################
        const [
            getAllSettings,
            {
                isLoading: settingsLoading,
                isSuccess: settingsSuccess,
                data: settingsData,
            },
        ] = useLazyGetAllSettingsQuery();
        const rtkSettingsActions = (data) => {
            dispatch(setSettings(data));
        };
        useRTKFetch(settingsSuccess, settingsData, rtkSettingsActions);
        // ################# Promotion Customer #################
        const [
            getPromotionsCustomers,
            {
                isLoading: customersLoading,
                isSuccess: customersSuccess,
                data: customersData,
            },
        ] = useLazyGetPromotionsCustomersQuery();
        const rtkCustomerActions = (data) => {
            dispatch(setCustomers(data));
        };
        useRTKFetch(customersSuccess, customersData, rtkCustomerActions);
        // ################# Jobs #################
        // => ORG
        const [
            getORGJobs,
            { isLoading: jobsLoading, isSuccess: jobsSuccess, data: jobsData },
        ] = useLazyGetORGJobsQuery();
        const rtkJobsActions = (data) => {
            dispatch(setOrganizationJobs(data));
        };
        useRTKFetch(jobsSuccess, jobsData, rtkJobsActions);
        // => Employers
        const [
            getEmployerJobs,
            {
                isLoading: employerJobsLoading,
                isSuccess: employerJobsSuccess,
                data: employerJobsData,
            },
        ] = useLazyGetAllEmployerJobsQuery();
        const rtkEmployerJobsActions = (data) => {
            dispatch(setAllEmployerJobs(data));
        };
        useRTKFetch(
            employerJobsSuccess,
            employerJobsData,
            rtkEmployerJobsActions,
        );

        // ################# Employers #################
        const [
            getAllEmployers,
            {
                isLoading: employersLoading,
                isSuccess: employersSuccess,
                data: employersData,
            },
        ] = useLazyGetAllEmployersQuery();
        const rtkEmployersActions = (data) => {
            dispatch(setEmployers(data));
        };
        useRTKFetch(employersSuccess, employersData, rtkEmployersActions);

        // ################# Beneficiary #################
        const [
            getAllBeneficiary,
            {
                isLoading: beneficiaryLoading,
                isSuccess: beneficiarySuccess,
                data: beneficiaryData,
            },
        ] = useLazyGetAllBeneficiariesQuery();
        const rtkBeneficiaryActions = (data) => {
            dispatch(setBeneficiaries(data));
        };
        useRTKFetch(beneficiarySuccess, beneficiaryData, rtkBeneficiaryActions);

        // ################# Students #################
        const [
            getAllStudents,
            {
                isLoading: studentLoading,
                isSuccess: studentSuccess,
                data: studentData,
            },
        ] = useLazyGetAllStudentsQuery();
        const rtkStudentActions = (data) => {
            dispatch(setStudents(data));
        };
        useRTKFetch(studentSuccess, studentData, rtkStudentActions);

        // ################# Practical training #################
        const [
            getAllPracticalTrainings,
            {
                isLoading: trainingLoading,
                isSuccess: trainingSuccess,
                data: trainingData,
            },
        ] = useLazyGetAllTrainingsQuery();
        const rtkTrainingActions = (data) => {
            dispatch(setPracticalTrainings(data));
        };
        useRTKFetch(trainingSuccess, trainingData, rtkTrainingActions);

        // ################# Shop #################
        const [
            getAllShops,
            { isLoading: shopLoading, isSuccess: shopSuccess, data: shopData },
        ] = useLazyGetAllShopsQuery();
        const rtkShopsActions = (data) => {
            dispatch(setShops(data));
        };
        useRTKFetch(shopSuccess, shopData, rtkShopsActions);

        // ################# Shop #################
        const [
            getAllSubPackages,
            {
                // isLoading: SubPackagesLoading,
                isSuccess: SubPackagesSuccess,
                data: SubPackagesData,
            },
        ] = useLazyGetAllPackagesQuery();
        const rtkSubscriptionPackageActions = (data) => {
            dispatch(setSubscriptionPackages(data));
        };
        useRTKFetch(
            SubPackagesSuccess,
            SubPackagesData,
            rtkSubscriptionPackageActions,
        );

        // ############## useEffect #################
        useEffect(() => {
            let isSubscribed = true;
            if (isSubscribed) {
                if (isMainFetch) {
                    getBlogs();
                    getPromotions();
                    getAllOrganizations();
                    getPromotionsCustomers();
                    getAllSettings();
                    getORGJobs();
                    getAllEmployers();
                    getEmployerJobs();
                    getAllBeneficiary();
                    getAllStudents();
                    getAllPracticalTrainings();
                    getAllShops();
                    getAllSubPackages();
                }
                setTimeout(() => {
                    dispatch(disableMainFetch());
                }, 2000);
            }
            return () => {
                isSubscribed = false;
            };
        }, []);

        return orgLoading
            ? orgLoading
            : customersLoading
            ? customersLoading
            : settingsLoading
            ? settingsLoading
            : blogLoading
            ? blogLoading
            : promoLoading
            ? promoLoading
            : employersLoading
            ? employersLoading
            : jobsLoading
            ? jobsLoading
            : employerJobsLoading
            ? employerJobsLoading
            : beneficiaryLoading
            ? beneficiaryLoading
            : studentLoading
            ? studentLoading
            : shopLoading
            ? shopLoading
            : trainingLoading
            ? trainingLoading
            : false;
    };

    return {
        useRTKFetch,
        useRTKResponse,
        useRTKMainData,
    };
};

export default useRTK;
