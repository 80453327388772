import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { borderRadius, colors } from '../../assets/utils/constants';
import DataPagnation from './DataPagnation';
import strHelper from '../../helpers/strHelper';

// ############# CARD ###############
const Card = styled(Box)({
    borderRadius: 15,
    background: colors.bgColor2,
    border: `1px solid ${colors.bgColor4}`,
});

// ############# CARD HEADER ###############
const CardHeader = styled(Box)({
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'paddingTop': 12,
    'paddingBottom': 12,
    'paddingLeft': 15,
    'paddingRight': 15,
    'position': 'relative !important',
    '.head': {
        'fontSize': 16,
        'color': colors.primary,
        'fontWeight': 'bold',
        'opacity': 0.8,
        '::before': {
            content: "''",
            width: 90,
            height: 4,
            background: colors.bgColor5,
            position: 'absolute',
            bottom: 0,
        },
    },
});

// ############# CARD BODY ###############
const CardBody = styled(Box)({
    padding: 15,
    overflow: 'auto',
    position: 'relative',
    background: colors.bgColor1,
});

// ############# CARD FOOTER ###############
const CardFooter = styled(Box)(({ theme }) => ({}));

const CustomPagination = ({
    data,
    height,
    action,
    head,
    borderBottom,
    handlePageChange,
    handlePerPageChage,
    children,
}) => {
    const STR = strHelper();

    const currentPage = data?.page;
    const handleNext = (nextPage) => {
        handlePageChange(nextPage);
    };
    const handlePrev = (prevPage) => {
        handlePageChange(prevPage);
    };
    const handlePageSelect = (page) => {
        handlePageChange(page);
    };
    return (
        <>
            <Card
                sx={{
                    borderRadius: borderRadius.br1,
                    borderBottom: borderBottom
                        ? `15px solid ${colors.bgColor4}`
                        : `1px solid ${colors.bgColor4}`,
                }}
            >
                {/* ==== header ===== */}
                <CardHeader sx={{ display: head ? 'flex' : 'none' }}>
                    <Box>
                        <Typography className="head">{STR.strCapitalizeFirstChar(head)}</Typography>
                    </Box>
                    <Box>{action}</Box>
                </CardHeader>

                {/* ==== Body ===== */}
                <CardBody
                    sx={{
                        height: height,
                        overflow: 'auto',
                        position: 'relative',
                    }}
                >
                    {children}
                </CardBody>
                <CardFooter>
                    <DataPagnation
                        data={data}
                        currentPage={currentPage}
                        perPage={handlePerPageChage}
                        handlePageSelect={handlePageSelect}
                        handleNext={handleNext}
                        handlePrev={handlePrev}
                    />
                </CardFooter>
            </Card>
        </>
    );
};

export default CustomPagination;
