import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ErrorMessage, useField } from 'formik';

const CustomInput = styled(TextField)({});

const CustomTextField = ({ label, type, value, optional, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <>
            <Box
                sx={{
                    marginBottom: 2,
                    borderRadius: 1.5,
                    overflow: 'hidden',
                    position: 'relative',
                }}
            >
                {optional && (
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: 'bold',
                            opacity: 0.8,
                            position: 'absolute',
                            textAlign: 'right',
                            left: 0,
                            top: 3,
                            right: 10,
                        }}
                    >
                        <span className="error-text">**optional**</span>
                    </Typography>
                )}
                <CustomInput
                    error={meta.touched && meta.error ? true : false}
                    label={label}
                    variant="filled"
                    fullWidth
                    type={type}
                    placeholder={field.label}
                    {...field}
                    {...props}
                    focused={type === 'date' || type === 'time' ? true : false}
                />
                <ErrorMessage
                    component="span"
                    name={field.name}
                    className="error-text"
                />
            </Box>
        </>
    );
};

export default CustomTextField;
