import React from 'react';
import PageLayout from '../layouts/PageLayout';
import CustomCard2 from '../../components/cards/CustomCard2';
import { Grid } from '@mui/material';
import InnerDataFetchingError from '../../components/InnerDataFetchingError';
import ContentLoader from '../../components/ContentLoader';
import useRTK from '../../hooks/useRTK';
import { useDispatch, useSelector } from 'react-redux';
import { beneficiarySelector } from '../../states/features/selectors';
import { useParams } from 'react-router-dom';
import BeneficiarySidebar from '../../components/sidebars/BeneficiarySidebar';
import { useGetSingleBeneficiaryQuery } from '../../api/beneficiaries/beneficiaryAPI';
import { setBeneficiaryDetails } from '../../states/features/beneficiary/beneficiarySlice';
import BeneficiaryWidget2 from '../../components/widgets/BeneficiaryWidget2';
import { WorkHistory } from '@mui/icons-material';
import CustomTabs from '../../components/tabs/CustomTabs';
import BenGeneralDetails from './components/BenGeneralDetails';
import BenJobs from './components/BenJobs';
import BenShops from './components/BenShops';
import { isEmpty, size } from 'lodash';
import BenGroups from './components/BenGroups';

const BeneficiaryDetails = () => {
    // ################# CONST #####################
    const RTK = useRTK();
    const { beneficiary_id } = useParams();
    const dispatch = useDispatch();

    // ################# Redux state #####################
    const { beneficiaryDetails: beneficiary } = useSelector(
        beneficiarySelector,
    );

    // ############# RTK ##################
    // => Beneficiary details
    const {
        isLoading,
        isSuccess,
        isError,
        refetch,
        data: responseData,
    } = useGetSingleBeneficiaryQuery(beneficiary_id);
    const rtkAction = (data) => {
        dispatch(setBeneficiaryDetails(data));
    };
    RTK.useRTKFetch(isSuccess, responseData, rtkAction);

    // ############ Tabs #################
    const tabs = [
        {
            id: 1,
            title: 'General details',
            icon: <WorkHistory />,
            total: '',
            tabsContent: (
                <>
                    {!isEmpty(beneficiary) && (
                        <BenGeneralDetails beneficiary={beneficiary} />
                    )}
                </>
            ),
        },
        {
            id: 2,
            title: 'Beneficiary Jobs',
            icon: <WorkHistory />,
            chipColor: 'info',
            total: 0,
            tabsContent: (
                <>
                    <BenJobs />
                </>
            ),
        },
        {
            id: 3,
            title: 'Beneficiary groups',
            icon: <WorkHistory />,
            chipColor: 'info',
            total: 0,
            tabsContent: (
                <>
                    <BenGroups />
                </>
            ),
        },
        {
            id: 4,
            title: 'Beneficiary shops',
            icon: <WorkHistory />,
            chipColor: 'info',
            total: size(!isEmpty(beneficiary) ? beneficiary?.shops : []),
            tabsContent: (
                <>
                    <BenShops
                        shops={!isEmpty(beneficiary) ? beneficiary?.shops : []}
                    />
                </>
            ),
        },
    ];

    return (
        <>
            <PageLayout
                head="Beneficiary managements"
                sidebar={<BeneficiarySidebar />}
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        {isLoading ? (
                            <>
                                <CustomCard2
                                    height={isLoading ? '75vh' : '100%'}
                                >
                                    <ContentLoader />
                                </CustomCard2>
                            </>
                        ) : isError ? (
                            <>
                                <CustomCard2
                                    height={isLoading ? '80vh' : '100%'}
                                >
                                    <InnerDataFetchingError
                                        handleDataRefetch={refetch}
                                    />
                                </CustomCard2>
                            </>
                        ) : (
                            <>
                                <BeneficiaryWidget2 beneficiary={beneficiary} />
                            </>
                        )}
                    </Grid>
                    {!isLoading && (
                        <Grid item sm={12}>
                            <CustomTabs
                                tabs={tabs}
                                height={'50vh'}
                                noHeight={250}
                            />
                        </Grid>
                    )}
                </Grid>
            </PageLayout>
        </>
    );
};

export default BeneficiaryDetails;
