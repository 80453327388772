import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Badge } from '@mui/material';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { filter, isEmpty } from 'lodash';
import { organizationsSelector } from '../states/features/selectors';
import DashORGTable from './dash/DashORGTable';
import DashJobsTable from './dash/DashJobsTable';
import DashShopsTable from './dash/DashShopsTable';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 0, position: 'relative' }}>{children}</Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        'id': `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function PendingActivities() {
    // ############### Comp state #################
    const [value, setValue] = React.useState(0);
    const [orgVerReq, setOrgVerReq] = React.useState([]);

    // ############### Redux state #################
    const { organizations } = useSelector(organizationsSelector);

    // ############### FUNC #################
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const getVerificationOrgReq = () => {
        let verReq = filter(
            organizations,
            ({ is_verified }) =>
                is_verified === 'requested' || is_verified === 'on_progress',
        );
        setOrgVerReq(verReq);
    };

    // ############## USEEFFECT ######################
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            getVerificationOrgReq();
        }
        return () => {
            isSubscribed = false;
        };
    }, []);

    return (
        <>
            <Tabs value={value} onChange={handleChange} variant="fullWidth">
                <Tab
                    sx={{ fontWeight: 'bold', fontSize: 13 }}
                    label={
                        <Typography
                            sx={{
                                fontSize: 13,
                                fontWeight: 'bold',
                                opacity: 0.9,
                            }}
                        >
                            Pending org's
                            <Badge
                                badgeContent={
                                    !isEmpty(orgVerReq) ? orgVerReq.length : '0'
                                }
                                color="warning"
                                sx={{ paddingLeft: 4 }}
                            />
                        </Typography>
                    }
                    {...a11yProps(0)}
                />
                <Tab
                    sx={{ fontWeight: 'bold', fontSize: 13 }}
                    label={
                        <Typography
                            sx={{
                                fontSize: 13,
                                fontWeight: 'bold',
                                opacity: 0.9,
                            }}
                        >
                            Pending ben's
                            <Badge
                                badgeContent={1}
                                color="warning"
                                sx={{ paddingLeft: 4 }}
                            />
                        </Typography>
                    }
                    {...a11yProps(1)}
                />
                <Tab
                    sx={{ fontWeight: 'bold', fontSize: 13 }}
                    label={
                        <Typography
                            sx={{
                                fontSize: 13,
                                fontWeight: 'bold',
                                opacity: 0.9,
                            }}
                        >
                            Pending jobs
                            <Badge
                                badgeContent={1}
                                color="warning"
                                sx={{ paddingLeft: 4 }}
                            />
                        </Typography>
                    }
                    {...a11yProps(2)}
                />
                <Tab
                    sx={{ fontWeight: 'bold', fontSize: 13 }}
                    label={
                        <Typography
                            sx={{
                                fontSize: 13,
                                fontWeight: 'bold',
                                opacity: 0.9,
                            }}
                        >
                            Pending shops
                            <Badge
                                badgeContent={1}
                                color="warning"
                                sx={{ paddingLeft: 4 }}
                            />
                        </Typography>
                    }
                    {...a11yProps(3)}
                />
            </Tabs>
            <TabPanel value={value} index={0}>
                <DashORGTable organizations={orgVerReq} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <DashJobsTable organizations={orgVerReq} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <DashShopsTable organizations={orgVerReq} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <DashShopsTable organizations={orgVerReq} />
            </TabPanel>
        </>
    );
}
