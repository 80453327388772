import React from 'react';
import { paginator } from '../../helpers/paginationHelper';
import CustomPagination from './CustomPagination';
import { isEmpty, size } from 'lodash';
import NoContent from '../NoContent';
import { Box, Grid } from '@mui/material';
import CountBadge from '../CountBadge';
import ORGJobWidget from '../widgets/ORGJobWidget';
import EmployerJobWidget from '../widgets/EmployerJobWidget';

const JobPagination = ({ jobs, height, head, type }) => {
    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(10);

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };

    // => Pagination data
    const paginatedJobs = paginator(jobs, page, perPage);

    return (
        <div>
            <CustomPagination
                head={head ? head : ''}
                height={height ? height : '45vh'}
                borderBottom
                handlePageChange={handlePageChange}
                handlePerPageChage={handlePerPageChage}
                data={paginatedJobs}
                action={
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ ml: 1 }}>
                            <CountBadge total={size(jobs)} item={'Jobs'} />
                        </Box>
                    </Box>
                }
            >
                {isEmpty(jobs) ? (
                    <>
                        <NoContent height={200} message={'No job posted'} />
                    </>
                ) : (
                    <Grid container spacing={1}>
                        {paginatedJobs.data.map((job) => (
                            <Grid item sm={12} xs={12} key={job.id}>
                                {type === 'org' ? (
                                    <ORGJobWidget job={job} />
                                ) : (
                                    <EmployerJobWidget job={job} />
                                )}
                            </Grid>
                        ))}
                    </Grid>
                )}
            </CustomPagination>
        </div>
    );
};

export default JobPagination;
