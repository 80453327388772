// ! ############################ Action Selectors ###########################
// => Auth selector
export const authSelector = (state) => state.authReducer;

// => Blog selector
export const blogsSelector = (state) => state.blogsReducer;

// => Promotion selector
export const promotionsSelector = (state) => state.promotionsReducer;

// => Promotion customer selector
export const promotionCustomersSelector = (state) =>
    state.promotionCustomersReducer;

// => Faq selector
export const faqsSelector = (state) => state.faqsReducer;

// => Jobs selector
export const jobsSelector = (state) => state.jobsReducer;

// => Shops selector
export const shopsSelector = (state) => state.shopsReducer;

// => Practical Trainings selector
export const practicalTrainingsSelector = (state) =>
    state.practicalTrainingsReducer;

// ! ############################ Settings Selectors ###########################
// => Setting selector
export const settingsSelector = (state) => state.settingsReducer;

// => User status selector
export const userStatusSelector = (state) => state.userStatusReducer;

// => User roles selector
export const userRolesSelector = (state) => state.userRolesReducer;

// => Verification types selector
export const verificationTypesSelector = (state) =>
    state.verificationTypesReducer;

// => Group statuses selector
export const groupStatusesSelector = (state) => state.groupStatusReducer;

// => Payment methods selector
export const paymentMethodsSelector = (state) => state.paymentMethodsReducer;

// => Sectors selector
export const sectorsSelector = (state) => state.sectorsReducer;

// => identification types selector
export const identificationTypesSelector = (state) =>
    state.identificationTypesReducer;

// => job Categories selector
export const jobCategoriesSelector = (state) => state.jobCategoriesReducer;

// => organization Categories selector
export const organizationCategoriesSelector = (state) =>
    state.organizationCategoriesReducer;

// => Organization type selector
export const organizationTypesSelector = (state) =>
    state.organizationTypesReducer;

// => department selector
export const departmentsSelector = (state) => state.departimentsReducer;

// => education level selector
export const educationLevelsSelector = (state) => state.educationLevelsReducer;

// => experience level selector
export const experienceLevelsSelector = (state) =>
    state.experienceLevelsReducer;

// => qualification selector
export const qualificationAwardsSelector = (state) =>
    state.qualificationAwardsReducer;

// => section selector
export const sectionsSelector = (state) => state.sectionsReducer;

// => Organizations selector
export const organizationsSelector = (state) => state.organizationsReducer;

// => Help center selector
export const helpCenterSelector = (state) => state.helpCenterReducer;

// => Subscription Packages selector
export const subscriptionPackagesSelector = (state) =>
    state.subscriptionPackagesReducer;

// => Notification selector
export const notificationsSelector = (state) => state.notificationsReducer;

// ! ############################ User Selectors ###########################
// => Employer selector
export const employersSelector = (state) => state.employersReducer;

// => Beneficiary selector
export const beneficiarySelector = (state) => state.beneficiaryReducer;

// => Students selector
export const studentsSelector = (state) => state.studentsReducer;
