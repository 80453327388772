import React from 'react';
import PageLayout from '../layouts/PageLayout';
import InfoboxWidget2 from '../../components/widgets/InfoboxWidget2';
import { Box, Grid } from '@mui/material';
import { colors } from '../../assets/utils/constants';
import { paginator } from '../../helpers/paginationHelper';
import { useSelector } from 'react-redux';
import { beneficiarySelector } from '../../states/features/selectors';
import { filter, isEmpty, size } from 'lodash';
import CountBadge from '../../components/CountBadge';
import NoContent from '../../components/NoContent';
import CustomPagination from '../../components/paginations/CustomPagination';
import BeneficiarySidebar from '../../components/sidebars/BeneficiarySidebar';
import BeneficiaryWidget from '../../components/widgets/BeneficiaryWidget';

const Beneficiaries = () => {
    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(10);

    // ############# Redux state #############
    const { beneficiaries } = useSelector(beneficiarySelector);

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Beneficiary by status
    const getBeneficiaryByStatus = () => {
        // => Verified
        let verifiedBeneficiaries = filter(
            beneficiaries,
            ({ is_verified }) => is_verified === 1,
        );
        // => Requested
        let requestedBeneficiaries = filter(
            beneficiaries,
            ({ verificationStatus }) => verificationStatus === 'requested',
        );
        // => Denied
        let deniedBeneficiaries = filter(
            beneficiaries,
            ({ verificationStatus }) => verificationStatus === 'denied',
        );
        return {
            verifiedBeneficiaries,
            requestedBeneficiaries,
            deniedBeneficiaries,
        };
    };
    const beneficiaryByStatus = getBeneficiaryByStatus();

    const infoBoxData = [
        {
            id: 1,
            title1: 'Verified beneficiaries',
            title2: '',
            total: size(beneficiaryByStatus.verifiedBeneficiaries),
            url: 'status/verified',
            color: colors.primary,
        },
        {
            id: 2,
            title1: 'Verification request',
            title2: '',
            total: size(beneficiaryByStatus.requestedBeneficiaries),
            color: colors.warning,
            url: 'status/requested',
        },
        {
            id: 3,
            title1: 'Denied request',
            title2: '',
            total: size(beneficiaryByStatus.deniedBeneficiaries),
            color: 'red',
            url: 'status/denied',
        },
    ];

    // => Pagination data
    const paginatedBeneficiaries = paginator(
        beneficiaryByStatus.verifiedBeneficiaries,
        page,
        perPage,
    );

    return (
        <>
            <PageLayout
                head="Beneficiary managements"
                sidebar={<BeneficiarySidebar />}
            >
                <Grid container spacing={1.5}>
                    {infoBoxData.map((data) => (
                        <Grid item sm={4} key={data.id}>
                            <InfoboxWidget2 data={data} />
                        </Grid>
                    ))}
                    <Grid item sm={12}>
                        <CustomPagination
                            head={'Verified Wazawa Max beneficiaries'}
                            height={'51vh'}
                            borderBottom
                            handlePageChange={handlePageChange}
                            handlePerPageChage={handlePerPageChage}
                            data={paginatedBeneficiaries}
                            action={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box sx={{ ml: 1 }}>
                                        <CountBadge
                                            total={size(
                                                beneficiaryByStatus.verifiedBeneficiaries,
                                            )}
                                            item={'Active beneficiary'}
                                        />
                                    </Box>
                                </Box>
                            }
                        >
                            {isEmpty(
                                beneficiaryByStatus.verifiedBeneficiaries,
                            ) ? (
                                <>
                                    <NoContent
                                        height={300}
                                        message={'No verified beneficiary'}
                                    />
                                </>
                            ) : (
                                <Grid container spacing={3}>
                                    {paginatedBeneficiaries.data.map(
                                        (beneficiary) => (
                                            <Grid
                                                item
                                                sm={4}
                                                key={beneficiary.id}
                                            >
                                                <BeneficiaryWidget
                                                    beneficiary={beneficiary}
                                                />
                                            </Grid>
                                        ),
                                    )}
                                </Grid>
                            )}
                        </CustomPagination>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default Beneficiaries;
