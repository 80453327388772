import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import { filter, size } from 'lodash';
import {
    beneficiarySelector,
    subscriptionPackagesSelector,
} from '../../states/features/selectors';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const BeneficiarySidebar = () => {
    // ############# Redux state #############
    const { beneficiaries } = useSelector(beneficiarySelector);
    const { subscriptionPackages } = useSelector(subscriptionPackagesSelector);

    // ############# FUNC ####################
    // => Beneficiary by status
    const getBeneficiaryByStatus = () => {
        // => Verified
        let verifiedBeneficiaries = filter(
            beneficiaries,
            ({ is_verified }) => is_verified === 1,
        );
        // => Requested
        let requestedBeneficiaries = filter(
            beneficiaries,
            ({ verificationStatus }) => verificationStatus === 'requested',
        );
        // => Denied
        let deniedBeneficiaries = filter(
            beneficiaries,
            ({ verificationStatus }) => verificationStatus === 'denied',
        );
        // => Banned
        let bannedBeneficiaries = filter(
            beneficiaries,
            ({ status }) => status === 'banned',
        );
        return {
            verifiedBeneficiaries,
            requestedBeneficiaries,
            deniedBeneficiaries,
            bannedBeneficiaries,
        };
    };
    const beneficiaryByStatus = getBeneficiaryByStatus();

    return (
        <>
            <Box
                sx={{
                    p: 1,
                    borderRadius: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                }}
            >
                <Typography
                    sx={{
                        fontSize: 16,
                        opacity: 0.8,
                        fontWeight: 'bold',
                    }}
                >
                    Beneficiaries stats
                </Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    px: 1.5,
                    py: 2,
                    borderRadius: 2,
                    mt: 1,
                    boxShadow: `1px -1px 10px 0px ${colors.bgColor4}`,
                    background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                }}
            >
                <Box
                    sx={{
                        height: 150,
                        width: 150,
                        borderRadius: 50,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        border: `5px solid ${colors.bgColor4}`,
                        boxShadow: `0px 10px 15px ${colors.bgColor5}`,
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor3})`,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 35,
                            fontWeight: 'bold',
                            opacity: 0.8,
                            color: colors.info,
                        }}
                    >
                        {size(beneficiaries)}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: 14,
                            fontWeight: 'bold',
                            opacity: 0.8,
                            fontStyle: 'italic',
                        }}
                    >
                        {'Beneficiaries'}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        mt: 3,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Grid container spacing={3}>
                        <Grid item sm={6}>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    color: colors.primary,
                                    borderBottom: `3px solid ${colors.bgColor5}`,
                                }}
                            >
                                {size(beneficiaries)}
                                <sup
                                    style={{
                                        color: colors.primary,
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {' '}
                                    Registered
                                </sup>
                            </Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    color: colors.info,
                                    borderBottom: `3px solid ${colors.bgColor5}`,
                                }}
                            >
                                {size(
                                    beneficiaryByStatus.verifiedBeneficiaries,
                                )}
                                <sup
                                    style={{
                                        color: colors.success,
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {' '}
                                    Verified
                                </sup>
                            </Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    color: colors.info,
                                    borderBottom: `3px solid ${colors.bgColor5}`,
                                }}
                            >
                                {size(
                                    beneficiaryByStatus.requestedBeneficiaries,
                                )}
                                <sup
                                    style={{
                                        color: colors.info,
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {' '}
                                    Requested
                                </sup>
                            </Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography
                                sx={{
                                    fontSize: 20,
                                    color: 'red',
                                    borderBottom: `3px solid ${colors.bgColor5}`,
                                }}
                            >
                                {size(beneficiaryByStatus.deniedBeneficiaries)}
                                <sup
                                    style={{
                                        color: 'red',
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {' '}
                                    Denied
                                </sup>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box
                sx={{
                    mt: 10,
                    display: 'flex',
                    borderRadius: 3,
                    overflow: 'hidden',
                }}
            >
                <Box
                    sx={{
                        bgcolor: colors.primary,
                        width: '60%',
                        height: 120,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: ' center',
                    }}
                >
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            sx={{
                                fontSize: 35,
                                fontWeight: 'bold',
                                fontStyle: 'italic',
                                color: colors.secondary,
                            }}
                        >
                            {size(
                                filter(
                                    subscriptionPackages,
                                    ({ type }) => type === 'Talent tap',
                                ),
                            )}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: colors.secondary,
                                fontWeight: 'bold',
                            }}
                        >
                            packages
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        bgcolor: colors.secondary,
                        width: '100%',
                        height: 120,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: ' center',
                    }}
                >
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
                            Talent tap
                        </Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
                            Subscription Packages
                        </Typography>
                        <Link to={'/subscription-packages'}>
                            <Button
                                size="small"
                                variant="contained"
                                fullWidth
                                sx={{ mt: 2 }}
                            >
                                Manage
                            </Button>
                        </Link>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default BeneficiarySidebar;
