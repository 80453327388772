import { authAPI } from '../../api/auth/authAPI';
import { beneficiaryAPI } from '../../api/beneficiaries/beneficiaryAPI';
import { blogsAPI } from '../../api/blogs/blogsAPI';
import { employersAPI } from '../../api/employers/employersAPI';
import { faqsAPI } from '../../api/faqs/faqsAPI';
import { helpCenterAPI } from '../../api/helpCenter/helpCenterAPI';
import { jobAPI } from '../../api/job/jobAPI';
import { notificationsAPI } from '../../api/notifications/notificationsAPI';
import { organizationAPI } from '../../api/organizations/organizationAPI';
import { practicalTrainingsAPI } from '../../api/practicalTrainings/practicalTrainingsAPI';
import { promotionsAPI } from '../../api/promotions/promotionsAPI';
import { promotionsCustomersAPI } from '../../api/promotions/promotionsCustomersAPI';
import { departmentsAPI } from '../../api/settings/departmentsAPI';
import { educationLevelAPI } from '../../api/settings/educationLevelAPI';
import { experienceLevelAPI } from '../../api/settings/experienceLevelAPI';
import { groupStatusAPI } from '../../api/settings/groupStatusAPI';
import { identificationTypesAPI } from '../../api/settings/identificationTypesAPI';
import { jobCategoriesAPI } from '../../api/settings/jobCategoriesAPI';
import { organizationCategoriesAPI } from '../../api/settings/organizationCategoriesAPI';
import { organizationTypesAPI } from '../../api/settings/organizationTypesAPI';
import { paymentMethodsAPI } from '../../api/settings/paymentMethodsAPI';
import { qualificationAwardsAPI } from '../../api/settings/qualificationAwardsAPI';
import { sectionsAPI } from '../../api/settings/sectionsAPI';
import { sectorsAPI } from '../../api/settings/sectorsAPI';
import { settingAPI } from '../../api/settings/settingAPI';
import { userRolesAPI } from '../../api/settings/userRolesAPI';
import { userStatusAPI } from '../../api/settings/userStatusAPI';
import { verificationTypesAPI } from '../../api/settings/verificationTypesAPI';
import { shopAPI } from '../../api/shops/shopAPI';
import { studentsAPI } from '../../api/students/studentsAPI';
import { subscriptionPackagesAPI } from '../../api/subscriptionPackages/subscriptionPackagesAPI';

// => RTK middleware
export const RTKmiddleware = [
    authAPI.middleware,
    settingAPI.middleware,
    userStatusAPI.middleware,
    organizationAPI.middleware,
    userRolesAPI.middleware,
    verificationTypesAPI.middleware,
    groupStatusAPI.middleware,
    paymentMethodsAPI.middleware,
    sectorsAPI.middleware,
    identificationTypesAPI.middleware,
    jobCategoriesAPI.middleware,
    organizationCategoriesAPI.middleware,
    organizationTypesAPI.middleware,
    departmentsAPI.middleware,
    educationLevelAPI.middleware,
    experienceLevelAPI.middleware,
    qualificationAwardsAPI.middleware,
    sectionsAPI.middleware,
    blogsAPI.middleware,
    promotionsAPI.middleware,
    promotionsCustomersAPI.middleware,
    faqsAPI.middleware,
    jobAPI.middleware,
    employersAPI.middleware,
    helpCenterAPI.middleware,
    beneficiaryAPI.middleware,
    shopAPI.middleware,
    studentsAPI.middleware,
    subscriptionPackagesAPI.middleware,
    practicalTrainingsAPI.middleware,
    notificationsAPI.middleware,
];
