import React from 'react';
import { Box, Grid, MenuItem } from '@mui/material';
import { Form, Formik } from 'formik';
import CustomTextField from '../../../components/Forms/CustomTextField';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import { Save } from '@mui/icons-material';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import useRTK from '../../../hooks/useRTK';
import CustomTextArea from '../../../components/Forms/CustomTextArea';
import CustomSelectInput from '../../../components/Forms/CustomSelectInput';
import { useCreateSubscriptionPackageMutation } from '../../../api/subscriptionPackages/subscriptionPackagesAPI';
import { setSubscriptionPackages } from '../../../states/features/subscriptionPackages/subscriptionPackagesSlice';

const createPackageValidationSchema = Yup.object({
    name: Yup.string().required(),
    type: Yup.string().required(),
    price: Yup.string().required(),
    duration: Yup.string().required(),
    description: Yup.string().max(100).nullable(),
});

const CreateSubscriptionPackage = ({ handleDialogClose, type }) => {
    // ############# CONST ##################
    const RTK = useRTK();
    const dispatch = useDispatch();
    const getDays = () => {
        let durations = [];
        for (let index = 1; index < 31; index++) {
            durations.push(index);
        }
        return durations;
    };
    const durations = getDays();
    const packageTypes = [
        {
            id: 1,
            type: 'Talent tap',
        },
        {
            id: 2,
            type: 'Market mingle',
        },
        {
            id: 3,
            type: 'Promo connect',
        },
    ];

    // ############# RTK ##################
    const [
        createPackage,
        { isLoading, isSuccess: addSuccess, isError, error, data: addData },
    ] = useCreateSubscriptionPackageMutation();

    // ############# useEffect ##################
    const rtkActions = (data) => {
        handleDialogClose();
        dispatch(setSubscriptionPackages(data));
    };
    RTK.useRTKResponse(addSuccess, isError, error, addData, rtkActions);

    return (
        <>
            <Box>
                <Formik
                    initialValues={{
                        name: '',
                        type: type,
                        price: '',
                        duration: '',
                        description: '',
                    }}
                    validationSchema={createPackageValidationSchema}
                    onSubmit={(payload) => {
                        createPackage(payload);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Grid
                                container
                                justifyContent={'center'}
                                columnSpacing={2}
                            >
                                <Grid item sm={12} xs={12}>
                                    <CustomTextField
                                        name="name"
                                        type={'text'}
                                        label={'Package name'}
                                    />
                                </Grid>
                                <Grid item sm={4} xs={4}>
                                    <CustomSelectInput
                                        type="text"
                                        name="type"
                                        label="Package type"
                                        formik={formik}
                                        color="primary"
                                    >
                                        {packageTypes.map((type) => (
                                            <MenuItem
                                                value={type.type}
                                                key={type.id}
                                            >
                                                {type.type}
                                            </MenuItem>
                                        ))}
                                    </CustomSelectInput>
                                </Grid>
                                <Grid item sm={4} xs={4}>
                                    <CustomTextField
                                        name="price"
                                        type={'text'}
                                        label={'Package price'}
                                    />
                                </Grid>
                                <Grid item sm={4} xs={4}>
                                    <CustomSelectInput
                                        type="text"
                                        name="duration"
                                        label="Package duration"
                                        formik={formik}
                                        color="primary"
                                    >
                                        {durations.map((duration, index) => (
                                            <MenuItem
                                                value={duration}
                                                key={index}
                                            >
                                                {duration + ` days`}
                                            </MenuItem>
                                        ))}
                                    </CustomSelectInput>
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <CustomTextArea
                                        name="description"
                                        type={'text'}
                                        label={'Package description'}
                                        rows={3}
                                        maxChar={100}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={12}
                                    sx={{
                                        mt: 2,
                                    }}
                                >
                                    <CustomSubmitButton
                                        loading={isLoading}
                                        startIcon={<Save />}
                                        onClick={formik.handleSubmit}
                                        btnColor={'primary'}
                                        sx={{
                                            py: 2,
                                        }}
                                    >
                                        Save package
                                    </CustomSubmitButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
        </>
    );
};

export default CreateSubscriptionPackage;
