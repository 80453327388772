import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import TodayActivitieWidget from '../widgets/TodayActivitieWidget';

const TodayActivities = () => {
    const todayActivities = [
        {
            id: 1,
            title: 'Posted jobs',
            total: 12,
            url: '/jobs',
        },
        {
            id: 2,
            title: 'Registered fundi',
            total: 12,
            url: '/employers',
        },
        {
            id: 3,
            title: 'Fundi subscription',
            total: 12,
            url: '/employers',
        },
        {
            id: 4,
            title: 'Reg. Beneficiaries',
            total: 12,
            url: '/beneficiaries',
        },
        {
            id: 5,
            title: 'Applied jobs',
            total: 12,
            url: '/jobs',
        },
        {
            id: 6,
            title: 'Posted fields',
            total: 12,
            url: '/practical-trainings',
        },
    ];
    return (
        <>
            <Box
                sx={{
                    p: 1.5,
                    borderRadius: 2,
                    // border: `1px dotted ${colors.primary}`,
                    background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                    boxShadow: `1px 1px 5px ${colors.bgColor4}`,
                }}
            >
                <Grid container spacing={2}>
                    <Grid item sm={1.5}>
                        <Box
                            sx={{
                                height: '100%',
                                background: `linear-gradient(90deg, ${colors.secondary}, ${colors.secondary})`,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 2,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                    opacity: 0.8,
                                    color: colors.primary,
                                }}
                            >
                                Today
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    textTransform: 'uppercase',
                                    fontWeight: 'bold',
                                    opacity: 0.8,
                                    color: colors.primary,
                                }}
                            >
                                Activities
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item sm={10.5} sx={{ width: '100%' }}>
                        <Grid container spacing={2}>
                            {todayActivities.map((activity) => (
                                <Grid item sm={2} key={activity.id}>
                                    <TodayActivitieWidget activity={activity} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default TodayActivities;
