// LOGIN user
const LOGIN = '/auth/login';

// UPDATE profile
const UPDATE_PROFILE = '/auth/update-profile';

// CHANGE password
const CHANGE_PASSWORD = '/auth/change-password';

// LOGOUT user
const LOGOUT = '/auth/logout';

export const authEndpoints = {
    LOGIN,
    LOGOUT,
    UPDATE_PROFILE,
    CHANGE_PASSWORD,
};
