import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import Loader from 'react-js-loader';
import dp from '../assets/media/images/user.jpg';
import {
    Typography,
    Badge,
    Avatar,
    Grid,
    Stack,
    Box,
    Button,
} from '@mui/material';

// material icons imports
import LogoutIcon from '@mui/icons-material/Logout';

// helper functions
import { colors } from '../assets/utils/constants';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { filter } from 'lodash';
import { size } from 'lodash';
import {
    BadgeOutlined,
    CastForEducationOutlined,
    DocumentScanner,
    EngineeringOutlined,
    Groups,
    School,
    SmartDisplayOutlined,
    Store,
    StorefrontOutlined,
    SubscriptionsOutlined,
    Window,
    WorkOutlineTwoTone,
} from '@mui/icons-material';
import {
    authSelector,
    beneficiarySelector,
    employersSelector,
    jobsSelector,
    organizationsSelector,
    practicalTrainingsSelector,
    promotionsSelector,
    shopsSelector,
    studentsSelector,
} from '../states/features/selectors';
import { useLogoutMutation } from '../api/auth/authAPI';
import useAuth from '../hooks/useAuth';
import { successNotify } from '../helpers/notify';

//############# Sidebar Mui Proparty #################
const drawerWidth = 240;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

//############# DrawerDesktop #################
const DrawerDesktop = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    'width': drawerWidth,
    'flexShrink': 0,
    'whiteSpace': 'nowrap',
    'boxSizing': 'border-box',
    '& .MuiPaper-root': {
        background: `linear-gradient(180deg, ${colors.primary}, ${colors.primary}) !important`,
        border: 'none !important',
        padding: 0,
        paddingTop: 0,
        boxShadow: `0px 0px 4px 0px ${colors.bgColor5}`,
    },
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const UserCard = styled(ListItem)(({ theme }) => ({
    'display': 'flex',
    'alignItems': 'center',
    'backgroundColor': colors.secondary,
    'padding': '8px 5px 8px 5px',
    '& .details': {
        marginLeft: 15,
    },
    '& .name': {
        fontSize: 14,
        padding: 0,
        margin: 0,
        fontWeight: 'bold',
    },
    '& .position': {
        fontSize: 14,
        padding: 0,
        margin: 0,
    },
    '&:hover': {
        opacity: 0.9,
    },
}));
const UserDetails = styled(Stack)({});

//############# SideNavListItem #################
const SideNavListItem = styled(ListItem)(({ theme }) => ({
    'paddingTop': 0,
    'transition': 'all ease-in-out 0.3s',
    '&::before': {
        content: '""',
        position: 'absolute',
        height: 0,
        bottom: '50%',
        width: 4,
        transition: 'all ease-in-out 0.3s',
        backgroundColor: colors.bgColor1,
    },
    '& .icon': {
        minWidth: 0,
        justifyContent: 'center',
        color: colors.bgColor1,
        opacity: 0.9,
    },
    '& .name': {
        color: colors.bgColor1,
    },
}));

const SidebarBottom = styled(Stack)(({ theme }) => ({
    position: 'relative',
    bottom: 0,
    right: 0,
    left: 0,
    marginTop: 10,
    backgroundColor: colors.gray,
}));

// !################ MAIN FUNC ##########################
const Sidebar = ({ handleDrawerToggle, mobileOpen }) => {
    const [open] = React.useState(true);

    const AppBarHeight = styled('div')(({ theme }) => ({
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    const { window } = 'props';
    const container =
        window !== undefined ? () => window().document.body : undefined;

    // ############### REDUCER STATE #################
    const auth = useAuth();
    const { user } = useSelector(authSelector);
    const { organizations } = useSelector(organizationsSelector);
    const institutions = filter(organizations, [
        'organization_type.name',
        'Institution',
    ]);
    const companies = filter(organizations, [
        'organization_type.name',
        'Company',
    ]);
    const { promotions } = useSelector(promotionsSelector);
    const { organizationJobs, allEmployerJobs } = useSelector(jobsSelector);
    const { employers } = useSelector(employersSelector);
    const { beneficiaries } = useSelector(beneficiarySelector);
    const { shops } = useSelector(shopsSelector);
    const { students } = useSelector(studentsSelector);
    const { practicalTrainings } = useSelector(practicalTrainingsSelector);

    // ############## RTK ######################
    const [logoutUser, { isSuccess, isLoading, isError }] = useLogoutMutation();

    React.useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                auth.removeAuthenticatedUser();
                setTimeout(() => {
                    successNotify('Logout successfully');
                }, 500);
            }
            if (isError) {
                auth.removeAuthenticatedUser();
                setTimeout(() => {
                    successNotify('Logout successfully');
                }, 500);
                // errorNotify('Fail to logout. Try again!');
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError, auth]);

    // =============== Nav links ================
    const users = [
        {
            id: 1,
            name: 'Institutions',
            icon: <CastForEducationOutlined />,
            url: '/organizations/institutions',
            tooltip: 'Institutions',
            total: size(institutions),
        },
        {
            id: 2,
            name: 'Companies',
            icon: <Store />,
            url: '/organizations/companies',
            tooltip: 'Companies',
            total: size(companies),
        },
        {
            id: 3,
            name: 'Students',
            icon: <School />,
            url: '/students',
            tooltip: 'Students',
            total: size(students),
        },
        {
            id: 4,
            name: 'Employers',
            icon: <BadgeOutlined />,
            url: '/employers',
            tooltip: 'Employers',
            total: size(employers),
        },
        {
            id: 5,
            name: 'Beneficiaries',
            icon: <Groups />,
            url: '/beneficiaries',
            tooltip: 'Beneficiaries',
            total: size(beneficiaries),
        },
    ];

    // =============== Actions ================
    const actions = [
        {
            id: 1,
            name: 'Jobs',
            icon: <WorkOutlineTwoTone />,
            url: '/jobs',
            tooltip: 'Jobs',
            total: size(organizationJobs) + size(allEmployerJobs),
        },
        {
            id: 2,
            name: 'Shops',
            icon: <StorefrontOutlined />,
            url: '/shops',
            tooltip: 'Employers',
            total: size(shops),
        },
        {
            id: 3,
            name: 'Promotions',
            icon: <SmartDisplayOutlined />,
            url: '/promotions',
            tooltip: 'Employers',
            total: size(promotions),
        },
        {
            id: 4,
            name: 'Practical training',
            icon: <EngineeringOutlined />,
            url: '/practical-trainings',
            tooltip: 'Employers',
            total: size(practicalTrainings),
        },
        // {
        //     id: 5,
        //     name: 'Finance management',
        //     icon: <PaidOutlined />,
        //     url: '/finance',
        //     tooltip: 'Finance management',
        // },
        {
            id: 6,
            name: 'Report & analytics',
            icon: <DocumentScanner />,
            url: '/report-and-analytics',
            tooltip: 'Report & analytics',
        },
    ];

    const drawer = (
        <>
            <RouterLink to={'/profile'}>
                <UserCard
                    sx={{ display: { xs: 'none', sm: 'flex' }, mt: 0 - 0.1 }}
                >
                    <UserDetails
                        sx={{
                            border: `2px solid ${colors.primary}`,
                            borderRadius: 50,
                            padding: 0.3,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor5})`,
                        }}
                    >
                        <Avatar
                            className="dp"
                            alt={`${user.first_name} ${user.middle_name} ${user.last_name}`}
                            src={
                                user.profile_picture ? user.profile_picture : dp
                            }
                            sx={{
                                width: 50,
                                height: 50,
                                img: {
                                    objectFit: 'contain',
                                },
                            }}
                        />
                    </UserDetails>
                    <UserDetails className="details">
                        <Typography className="name">
                            {`${user.first_name} ${user.middle_name} ${user.last_name}`}
                        </Typography>
                        <Typography className="position">
                            Super admin
                        </Typography>
                    </UserDetails>
                </UserCard>
            </RouterLink>
            <Box sx={{ px: 1.5 }}>
                {/* ============== Dashboard link =============== */}
                <List sx={{ marginTop: -1.2 }}>
                    <NavLink to="/dashboard">
                        {({ isActive }) => (
                            <SideNavListItem
                                disablePadding
                                sx={{
                                    'marginTop': 3,
                                    '&::before': isActive && {
                                        content: '""',
                                        position: 'absolute',
                                        height: '100%',
                                        bottom: 0,
                                        top: 0,
                                        width: 4,
                                        transition: 'all ease-in-out 0.3s',
                                        backgroundColor: colors.secondary,
                                    },
                                    '&:hover': !isActive && {
                                        'transition': 'all ease-in-out 0.3s',
                                        '&::before': {
                                            transition: 'all ease-in-out 0.3s',
                                            height: '100%',
                                            bottom: 0,
                                        },
                                    },
                                }}
                            >
                                <ListItemButton
                                    sx={{
                                        p: 0,
                                        pl: 1,
                                        pr: 2,
                                        my: 0.5,
                                    }}
                                >
                                    <ListItemIcon
                                        className="icon"
                                        sx={{ mr: open ? 1 : 'auto' }}
                                    >
                                        <Window />
                                    </ListItemIcon>
                                    <ListItemText
                                        className="name"
                                        sx={{ opacity: open ? 1 : 0 }}
                                        primaryTypographyProps={{
                                            fontWeight: 'bold',
                                            fontSize: 14,
                                        }}
                                        primary="Dashboard"
                                    />
                                </ListItemButton>
                            </SideNavListItem>
                        )}
                    </NavLink>

                    {/* #################### USER Links ######################## */}
                    <Box sx={{ mt: 1 }}>
                        <Typography
                            sx={{
                                color: colors.secondary,
                                fontSize: 13,
                                fontWeight: 'bold',
                                background: `linear-gradient(180deg, rgba(255,255,255, .3), ${colors.primary})`,
                                textAlign: 'center',
                                p: 0.5,
                            }}
                        >
                            Users
                        </Typography>
                        {users?.map((link, index) => (
                            <NavLink to={link.url} key={link.id}>
                                {({ isActive }) => (
                                    <SideNavListItem
                                        disablePadding
                                        sx={{
                                            'display': 'block',
                                            '&::before': isActive && {
                                                content: '""',
                                                position: 'absolute',
                                                height: '100%',
                                                bottom: 0,
                                                top: 0,
                                                width: 4,
                                                transition:
                                                    'all ease-in-out 0.3s',
                                                backgroundColor:
                                                    colors.secondary,
                                            },
                                            '&:hover': !isActive && {
                                                'transition':
                                                    'all ease-in-out 0.3s',
                                                '&::before': {
                                                    transition:
                                                        'all ease-in-out 0.3s',
                                                    height: '100%',
                                                    bottom: 0,
                                                },
                                            },
                                        }}
                                    >
                                        <ListItemButton
                                            sx={{
                                                p: 0,
                                                pl: 1,
                                                pr: 2,
                                                my: 0.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                className="icon"
                                                sx={{ mr: open ? 1 : 'auto' }}
                                            >
                                                {link.icon}
                                            </ListItemIcon>
                                            <ListItemText
                                                className="name"
                                                primary={link.name}
                                                sx={{ opacity: open ? 1 : 0 }}
                                                primaryTypographyProps={{
                                                    fontSize: 14,
                                                }}
                                            />
                                            <Badge
                                                className="badge"
                                                badgeContent={link.total}
                                                showZero
                                                sx={{
                                                    '& .MuiBadge-badge': {
                                                        color: colors.primary,
                                                        fontWeight: 'bold',
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, rgba(255,255,255, .7))`,
                                                    },
                                                }}
                                            />
                                        </ListItemButton>
                                    </SideNavListItem>
                                )}
                            </NavLink>
                        ))}
                    </Box>

                    {/* #################### ACTIONS ######################## */}
                    <Box sx={{ mt: 3 }}>
                        <Typography
                            sx={{
                                color: colors.secondary,
                                fontSize: 13,
                                fontWeight: 'bold',
                                background: `linear-gradient(180deg, rgba(255,255,255, .3), ${colors.primary})`,
                                textAlign: 'center',
                                p: 0.5,
                            }}
                        >
                            Actions
                        </Typography>
                        {actions?.map((link, index) => (
                            <NavLink to={link.url} key={link.id}>
                                {({ isActive }) => (
                                    <SideNavListItem
                                        disablePadding
                                        sx={{
                                            'display': 'block',
                                            '&::before': isActive && {
                                                content: '""',
                                                position: 'absolute',
                                                height: '100%',
                                                bottom: 0,
                                                top: 0,
                                                width: 4,
                                                transition:
                                                    'all ease-in-out 0.3s',
                                                backgroundColor:
                                                    colors.secondary,
                                            },
                                            '&:hover': !isActive && {
                                                'transition':
                                                    'all ease-in-out 0.3s',
                                                '&::before': {
                                                    transition:
                                                        'all ease-in-out 0.3s',
                                                    height: '100%',
                                                    bottom: 0,
                                                },
                                            },
                                        }}
                                    >
                                        <ListItemButton
                                            sx={{
                                                p: 0,
                                                pl: 1,
                                                pr: 2,
                                                my: 0.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                className="icon"
                                                sx={{ mr: open ? 1 : 'auto' }}
                                            >
                                                {link.icon}
                                            </ListItemIcon>
                                            <ListItemText
                                                className="name"
                                                primary={link.name}
                                                sx={{ opacity: open ? 1 : 0 }}
                                                primaryTypographyProps={{
                                                    fontSize: 14,
                                                }}
                                            />
                                            <Badge
                                                className="badge"
                                                badgeContent={link.total}
                                                showZero
                                                sx={{
                                                    '& .MuiBadge-badge': {
                                                        color: colors.primary,
                                                        fontWeight: 'bold',
                                                        background: `linear-gradient(180deg, ${colors.bgColor1}, rgba(255,255,255, .7))`,
                                                    },
                                                }}
                                            />
                                        </ListItemButton>
                                    </SideNavListItem>
                                )}
                            </NavLink>
                        ))}
                    </Box>
                </List>

                {/* ####################### Subscription packages ####################### */}
                <Box sx={{ mt: 3 }}>
                    <NavLink to="/subscription-packages">
                        <Button
                            startIcon={<SubscriptionsOutlined />}
                            variant="contained"
                            fullWidth
                            color="secondary"
                            sx={{
                                'height': 40,
                                'borderRadius': `5px !important`,
                                'fontWeight': 'bold !important',
                                'border': `1px solid ${colors.warning}`,
                                'color': colors.warning,
                                'background': `linear-gradient(180deg, ${colors.bgColor2}, ${colors.secondary})`,
                                ':hover': {
                                    bgcolor: colors.secondary,
                                    opacity: 0.9,
                                    border: `1px solid ${colors.bgColor5}`,
                                },
                            }}
                        >
                            Subscription packages
                        </Button>
                    </NavLink>
                </Box>

                {/* ========== bottom =============== */}
                <SidebarBottom>
                    <List sx={{ padding: 0 }}>
                        <Grid container>
                            <Grid item sm={12} xs={12}>
                                <ListItem
                                    disablePadding
                                    style={{
                                        backgroundColor: colors.bgColor1,
                                        color: colors.primary,
                                        justifyContent: 'center',
                                        borderRadius: 15,
                                        overflow: 'hidden',
                                    }}
                                >
                                    <ListItemButton
                                        style={{
                                            justifyContent: 'center',
                                            height: 50,
                                        }}
                                        onClick={() => {
                                            logoutUser();
                                        }}
                                    >
                                        {isLoading ? (
                                            <>
                                                <Box>
                                                    <Loader
                                                        type="bubble-loop"
                                                        bgColor={colors.danger}
                                                        color={colors.danger}
                                                        size={50}
                                                    />
                                                </Box>
                                            </>
                                        ) : (
                                            <>
                                                <LogoutIcon
                                                    className="icon"
                                                    sx={{
                                                        color: colors.danger,
                                                    }}
                                                />
                                                <Typography
                                                    component={'span'}
                                                    sx={{
                                                        color: colors.danger,
                                                        ml: 2,
                                                    }}
                                                >
                                                    Sign out
                                                </Typography>
                                            </>
                                        )}
                                    </ListItemButton>
                                </ListItem>
                            </Grid>
                        </Grid>
                    </List>
                </SidebarBottom>
            </Box>
        </>
    );
    return (
        <>
            {/* ################## mobile #################### */}
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    'display': { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': {
                        boxSizing: 'border-box',
                        width: '70%',
                        backgroundColor: colors.primary,
                    },
                }}
            >
                <Toolbar />
                {drawer}
            </Drawer>

            {/* ################## desktop #################### */}
            <DrawerDesktop
                variant="permanent"
                open={open}
                sx={{
                    display: { xs: 'none', sm: 'block' },
                }}
            >
                <AppBarHeight />
                {/* <DrawerHeader
                    sx={{
                        position: 'absolute',
                        top: 110,
                        zIndex: 1098,
                        right: -10,
                    }}
                >
                    {open ? (
                        <IconButton
                            className="icon"
                            onClick={handleDrawerClose}
                            sx={{
                                '&.icon': {
                                    border: `2.5px solid ${colors.primary}`,
                                },
                            }}
                        >
                            {theme.direction === 'rtl' ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronLeftIcon />
                            )}
                        </IconButton>
                    ) : (
                        <IconButton
                            className="icon"
                            onClick={handleDrawerOpen}
                            sx={{
                                '&.icon': {
                                    border: `2.5px solid ${colors.primary}`,
                                },
                            }}
                        >
                            {theme.direction === 'rtl' ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronRightIcon />
                            )}
                        </IconButton>
                    )}
                </DrawerHeader> */}
                <Divider />
                {drawer}
            </DrawerDesktop>
        </>
    );
};

export default Sidebar;
