import React from 'react';
import { Update } from '@mui/icons-material';
import { Box, Button, Chip, Typography } from '@mui/material';
import CustomCard2 from '../cards/CustomCard2';
import JobInfoBoxWidget from '../widgets/JobInfoBoxWidget';
import OrganizationJobWidget from '../widgets/OrganizationJobWidget';
import { colors } from '../../assets/utils/constants';
import { numFormater } from '../../helpers/numFormater';
import CustomCard from '../cards/CustomCard';
import { removeSpclChar } from '../../helpers/strHelper';
import SuspendUnsuspendJob from '../../pages/jobs/organizations/CRUD/SuspendUnsuspendJob';

const ORGJobDetailsSidebar = ({ jobDetails }) => {
    return (
        <>
            <Box
                sx={{
                    height: 85,
                    mb: 2,
                    background: `linear-gradient(180deg, ${colors.bgColor4}, ${colors.bgColor3})`,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 4,
                    position: 'relative',
                    boxShadow: `1px 1px 10px 0px ${colors.bgColor3}`,
                }}
            >
                <Box sx={{ position: 'absolute', top: 0, right: 5 }}>
                    <Chip
                        label={removeSpclChar(jobDetails.payment.status)}
                        size="small"
                        color={
                            jobDetails.payment.status === 'pending'
                                ? 'info'
                                : jobDetails.payment.status === 'completed'
                                ? 'success'
                                : jobDetails.payment.status === 'not_paid'
                                ? 'warning'
                                : jobDetails.payment.status === 'failed'
                                ? 'error'
                                : 'default'
                        }
                        sx={{ fontSize: 10, textTransform: 'capitalize' }}
                    />
                </Box>
                <Box
                    sx={{
                        width: 15,
                        background: `linear-gradient(90deg, ${colors.bgColor4}, ${colors.bgColor5})`,
                        position: 'absolute',
                        top: 20,
                        bottom: 20,
                        left: 0,
                        borderTopRightRadius: 100,
                        borderBottomRightRadius: 100,
                    }}
                ></Box>
                <Box
                    sx={{
                        width: 15,
                        background: `linear-gradient(360deg, ${colors.bgColor4}, ${colors.bgColor5})`,
                        position: 'absolute',
                        top: 20,
                        bottom: 20,
                        right: 0,
                        borderTopLeftRadius: 100,
                        borderBottomLeftRadius: 100,
                    }}
                ></Box>
                <Typography
                    sx={{
                        fontSize: 25,
                        fontWeight: 'bold',
                        color: colors.success,
                    }}
                >
                    {numFormater(jobDetails.payment.amount)}
                    <sup style={{ fontSize: 16 }}>TZS</sup>
                </Typography>
                <Typography
                    sx={{ fontSize: 16, fontWeight: 'bold', opacity: 0.8 }}
                >
                    Posting fee
                </Typography>
            </Box>
            <Box>
                <JobInfoBoxWidget jobDetails={jobDetails} />
            </Box>
            <Box sx={{ mt: 1 }}>
                <CustomCard head={'Posted by'}>
                    <OrganizationJobWidget
                        organization={jobDetails.organization}
                    />
                </CustomCard>
            </Box>
            <Box sx={{ mt: 1 }}>
                <CustomCard head={'Actions links'}>
                    <SuspendUnsuspendJob job={jobDetails} />
                </CustomCard>
            </Box>
        </>
    );
};

export default ORGJobDetailsSidebar;
