import { Box, Divider, Grid, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import { removeSpclChar } from '../../helpers/strHelper';

const JobInfoBoxWidget = ({ jobDetails }) => {
    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={6}>
                    <Box
                        className={
                            jobDetails.status === 'draft'
                                ? 'warning-box'
                                : jobDetails.status === 'published'
                                ? 'success-box'
                                : jobDetails.status === 'unpublished'
                                ? 'warning-box'
                                : jobDetails.status === 'closed'
                                ? ''
                                : jobDetails.status === 'expired'
                                ? ''
                                : jobDetails.status === 'banned'
                                ? 'error-box'
                                : ''
                        }
                        sx={{
                            borderRadius: 2,
                            flexDirection: 'column',
                            p: 0.4,
                            boxShadow: '3px 3px 3px 3px rgba(46, 119, 174,0.2)',
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                p: 0.5,
                                fontSize: 13,
                                color: colors.primary,
                                fontWeight: 'bold',
                                opacity: 0.8,
                            }}
                        >
                            Job status
                        </Typography>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                p: 0.5,
                                textTransform: 'capitalize',
                                fontSize: 18,
                                fontWeight: 'bold',
                            }}
                        >
                            {jobDetails.status}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={6}>
                    <Box
                        className={
                            jobDetails.payment.status === 'pending'
                                ? 'info-box'
                                : jobDetails.payment.status === 'completed'
                                ? 'success-box'
                                : jobDetails.payment.status === 'failed'
                                ? 'error-box'
                                : jobDetails.payment.status === 'not_paid'
                                ? 'warning-box'
                                : ''
                        }
                        sx={{
                            borderRadius: 2,
                            flexDirection: 'column',
                            p: 0.4,
                            boxShadow: '3px 3px 3px 3px rgba(46, 119, 174,0.2)',
                        }}
                    >
                        <Typography
                            sx={{
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                p: 0.5,
                                fontSize: 13,
                                color: colors.primary,
                                fontWeight: 'bold',
                                opacity: 0.8,
                            }}
                        >
                            Payment status
                        </Typography>
                        <Typography
                            sx={{
                                textAlign: 'center',
                                p: 0.5,
                                textTransform: 'capitalize',
                                fontSize: 18,
                                fontWeight: 'bold',
                            }}
                        >
                            {removeSpclChar(jobDetails.payment.status)}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    <Box
                        sx={{
                            bgcolor: colors.bgColor,
                            borderRadius: 2,
                            boxShadow: '3px 3px 3px 3px rgba(46, 119, 174,0.2)',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                p: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontWeight: 'bold',
                                    color: colors.primary,
                                    opacity: 0.8,
                                }}
                            >
                                Position
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    opacity: 0.8,
                                    mr: 2,
                                    color: colors.info,
                                    fontWeight: 'bold',
                                }}
                            >
                                {jobDetails.positions}
                            </Typography>
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                p: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontWeight: 'bold',
                                    color: colors.primary,
                                    opacity: 0.8,
                                }}
                            >
                                Max applicant
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    mr: 2,
                                    color: colors.info,
                                    fontWeight: 'bold',
                                }}
                            >
                                {jobDetails.max_applicants}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    <Box
                        sx={{
                            bgcolor: colors.bgColor,
                            borderRadius: 2,
                            boxShadow: '3px 3px 3px 3px rgba(46, 119, 174,0.2)',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                p: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontWeight: 'bold',
                                    color: colors.primary,
                                    opacity: 0.8,
                                }}
                            >
                                Open date
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    mr: 2,
                                    color: colors.info,
                                    fontWeight: 'bold',
                                }}
                            >
                                {moment(jobDetails.open_date).format('ll')}
                            </Typography>
                        </Box>
                        <Divider />
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                p: 1,
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontWeight: 'bold',
                                    color: colors.primary,
                                    opacity: 0.8,
                                }}
                            >
                                Close date
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    mr: 2,
                                    color: colors.info,
                                    fontWeight: 'bold',
                                }}
                            >
                                {moment(jobDetails.close_date).format('ll')}
                            </Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default JobInfoBoxWidget;
