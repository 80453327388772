import React from 'react';
import { Box, Grid, List, styled } from '@mui/material';
import { Form, Formik } from 'formik';
import CustomPasswordField from '../../components/Forms/CustomPasswordField';
import CustomSubmitButton from '../../components/Forms/CustomSubmitButton';
import { colors } from '../../assets/utils/constants';
import * as Yup from 'yup';
import { Lock } from '@mui/icons-material';

const InfoListCard = styled(List)({
    'padding': 0,
    'overflow': 'hidden',
    '& .MuiListItemButton-root': {
        'paddingLeft': 24,
        'paddingRight': 24,
        'background': colors.primary,
        '& span': {
            color: colors.gray,
        },
    },
    '& .list-item': {
        'background': colors.primary,
        'color': colors.gray,
        ' p': {
            color: colors.gray,
        },
    },
});

// ============== update password validation ===================
const validation = Yup.object({
    current_password: Yup.string().required('This field is required'),
    new_password: Yup.string().required('This field is required'),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('new_password'), null], 'New passwords must match')
        .required('This field is required'),
});

const ChangePassword = () => {
    return (
        <>
            <Grid container justifyContent={'space-around'}>
                <Grid item sm={12}>
                    <InfoListCard
                        sx={{
                            width: '100%',
                            bgcolor: 'background.paper',
                        }}
                    >
                        <Formik
                            initialValues={{
                                current_password: '',
                                new_password: '',
                                passwordConfirmation: '',
                            }}
                            validationSchema={validation}
                            onSubmit={(payload, { resetForm }) => {
                                // changePassword(payload)
                                resetForm();
                            }}
                        >
                            {(formik) => (
                                <Form>
                                    <Grid
                                        container
                                        justifyContent={'center'}
                                        padding={2}
                                        spacing={2}
                                    >
                                        <Grid item sm={12}>
                                            {/* ######### old password ######### */}
                                            <CustomPasswordField
                                                className="form-input"
                                                label="Current password"
                                                labelColor={colors.primary}
                                                type="password"
                                                name="current_password"
                                            />
                                        </Grid>
                                        {/* ######### new password ######### */}
                                        <Grid item sm={6}>
                                            <CustomPasswordField
                                                className="form-input"
                                                label="New password"
                                                labelColor={colors.primary}
                                                type="password"
                                                name="new_password"
                                            />
                                        </Grid>
                                        {/* ######### confirm password ######### */}
                                        <Grid item sm={6}>
                                            <CustomPasswordField
                                                className="form-input"
                                                label="Confirm new password"
                                                labelColor={colors.primary}
                                                type="password"
                                                name="passwordConfirmation"
                                            />
                                        </Grid>
                                        <Grid item sm={12}>
                                            <Box
                                                sx={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                <CustomSubmitButton
                                                    startIcon={<Lock />}
                                                    onClick={
                                                        formik.handleSubmit
                                                    }
                                                    sx={{
                                                        py: 2,
                                                    }}
                                                >
                                                    Update password
                                                </CustomSubmitButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </InfoListCard>
                </Grid>
            </Grid>
        </>
    );
};

export default ChangePassword;
