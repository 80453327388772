import React from 'react';
import PageLayout from '../layouts/PageLayout';
import { Grid } from '@mui/material';
import ShopSidebar from '../../components/sidebars/ShopSidebar';
import ShopWidget2 from '../../components/widgets/ShopWidget2';
import CustomCard2 from '../../components/cards/CustomCard2';
import { WorkHistory } from '@mui/icons-material';
import CustomTabs from '../../components/tabs/CustomTabs';
import { useGetSingleShopQuery } from '../../api/shops/shopAPI';
import { setShopDetails } from '../../states/features/shops/shopsSlice';
import { shopsSelector } from '../../states/features/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useRTK from '../../hooks/useRTK';
import ContentLoader from '../../components/ContentLoader';
import InnerDataFetchingError from '../../components/InnerDataFetchingError';
import { isEmpty } from 'lodash';
import ShopProducts from './components/ShopProducts';
import GeneralDetails from './components/GeneralDetails';

const ShopDetails = () => {
    // ################# CONST #####################
    const RTK = useRTK();
    const { shop_id } = useParams();
    const dispatch = useDispatch();

    // ################# Redux state #####################
    const { shopDetails: shop } = useSelector(shopsSelector);
    const shopProducts = shop?.products;

    // ############# RTK ##################
    // => Shop details
    const {
        isLoading,
        isSuccess,
        isError,
        refetch,
        data: responseData,
    } = useGetSingleShopQuery(shop_id);
    const rtkAction = (data) => {
        dispatch(setShopDetails(data));
    };
    RTK.useRTKFetch(isSuccess, responseData, rtkAction);

    // ############ Tabs #################
    const tabs = [
        {
            id: 1,
            title: 'General details',
            icon: <WorkHistory />,
            total: '',
            tabsContent: (
                <>
                    <CustomCard2
                        height={'43vh'}
                        borderBottom
                        bRadius={`0 !important`}
                    >
                        <GeneralDetails shop={shop} />
                    </CustomCard2>
                </>
            ),
        },
        {
            id: 2,
            title: 'Shop products',
            icon: <WorkHistory />,
            chipColor: 'info',
            total: 2,
            tabsContent: (
                <>
                    <CustomCard2
                        height={'43vh'}
                        borderBottom
                        bRadius={`0 !important`}
                    >
                        <ShopProducts shopProducts={shopProducts} />
                    </CustomCard2>
                </>
            ),
        },
    ];

    return (
        <>
            <PageLayout head="Shops managements" sidebar={<ShopSidebar />}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        {isLoading ? (
                            <>
                                <CustomCard2
                                    height={isLoading ? '75vh' : '100%'}
                                >
                                    <ContentLoader />
                                </CustomCard2>
                            </>
                        ) : isError ? (
                            <>
                                <CustomCard2
                                    height={isLoading ? '80vh' : '100%'}
                                >
                                    <InnerDataFetchingError
                                        handleDataRefetch={refetch}
                                    />
                                </CustomCard2>
                            </>
                        ) : (
                            <>{!isEmpty(shop) && <ShopWidget2 shop={shop} />}</>
                        )}
                    </Grid>
                    {!isLoading && (
                        <Grid item sm={12}>
                            <CustomTabs tabs={tabs} />
                        </Grid>
                    )}
                </Grid>
            </PageLayout>
        </>
    );
};

export default ShopDetails;
