import React, { useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import CustomCard2 from '../../components/cards/CustomCard2';
import { Add } from '@mui/icons-material';
import CustomDialog from '../../components/Dialogies/CustomDialog';
import TalentTapPackageWidget from '../../components/widgets/TalentTapPackageWidget';
import CreateSubscriptionPackage from './CRUD/CreateSubscriptionPackage';
import { isEmpty, size } from 'lodash';
import NoContent from '../../components/NoContent';
import CountBadge from '../../components/CountBadge';

const TalentTapPackages = ({ subscriptionPackages }) => {
    // ############## Comp State ##################
    const [dialogyOpen, setDialogyOpen] = useState(false);

    // ############## FUNC #####################
    // => Handle docs open
    const handleDialogOpen = () => {
        setDialogyOpen(true);
    };
    // => Dialogy closer
    const handleDialogClose = () => {
        setDialogyOpen(false);
    };

    return (
        <>
            {/* ################# Dialogy ################## */}
            <CustomDialog
                openDialog={dialogyOpen}
                handleDialogClose={handleDialogClose}
                head={'Add talent tap subscription package'}
            >
                <CreateSubscriptionPackage
                    type={'Talent tap'}
                    handleDialogClose={handleDialogClose}
                />
            </CustomDialog>

            <Grid container spacing={4} sx={{ mb: 5 }}>
                <Grid item sm={12}>
                    <CustomCard2
                        head={'Talent Tap Network Subscription Packages'}
                        // height={'71vh'}
                        borderBottom
                        action={
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Box sx={{ mr: 2 }}>
                                    <CountBadge
                                        total={size(subscriptionPackages)}
                                        item={'Packages'}
                                    />
                                </Box>
                                <Button
                                    startIcon={<Add />}
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => {
                                        handleDialogOpen();
                                    }}
                                >
                                    Add package
                                </Button>
                            </Box>
                        }
                    >
                        {isEmpty(subscriptionPackages) ? (
                            <>
                                <NoContent
                                    height={250}
                                    message={'No Talent Tap package added'}
                                />
                            </>
                        ) : (
                            <Grid container spacing={4}>
                                {subscriptionPackages.map(
                                    (subscriptionPackage) => (
                                        <Grid
                                            item
                                            sm={4}
                                            key={subscriptionPackage.id}
                                        >
                                            <TalentTapPackageWidget
                                                subscriptionPackage={
                                                    subscriptionPackage
                                                }
                                            />
                                        </Grid>
                                    ),
                                )}
                            </Grid>
                        )}
                    </CustomCard2>
                </Grid>
            </Grid>
        </>
    );
};

export default TalentTapPackages;
