import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Slide, Tooltip } from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';
import { colors } from '../../assets/utils/constants';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            <Tooltip title={'Close dialog'} arrow>
                <IconButton
                    className="neo-bulge"
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: colors.secondary,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </Tooltip>
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const ReportDialog = ({
    openDialog,
    handleDialogClose,
    head,
    noDropClose,
    children,
    customWidth,
}) => {
    return (
        <>
            <BootstrapDialog
                className="neo-bulge"
                onClose={noDropClose ? undefined : handleDialogClose}
                aria-labelledby="customized-dialog-title"
                open={openDialog}
                TransitionComponent={Transition}
                fullWidth
                fullScreen
                maxWidth={customWidth && customWidth}
                sx={{
                    'p': `0 !important`,
                    ' .MuiPaper-root': {
                        // borderRadius: 5,
                        boxShadow:
                            '-8px -8px 12px 0 rgba(0, 0, 0, .1) inset !important',
                        border: `10px solid ${colors.bgColor2}`,
                    },
                }}
            >
                <BootstrapDialogTitle
                    id="customized-dialog-title"
                    onClose={handleDialogClose}
                    sx={{
                        fontSize: 16,
                        fontWeight: 'bold',
                        bgcolor: colors.primary,
                        color: colors.secondary,
                    }}
                >
                    {head}
                </BootstrapDialogTitle>
                <DialogContent
                    sx={{
                        p: `0 !important`,
                        position: 'relative',
                        background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor1})`,
                    }}
                >
                    <PDFViewer style={{ height: '100%', width: '100%' }}>
                        {children}
                    </PDFViewer>
                </DialogContent>
            </BootstrapDialog>
        </>
    );
};

export default ReportDialog;
