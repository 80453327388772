import React from 'react';
import PageLayout from '../layouts/PageLayout';
import { Grid } from '@mui/material';
import TalentTapPackages from './TalentTapPackages';
import MarketMinglePackages from './MarketMinglePackages';
import PromoConnectPackages from './PromoConnectPackages';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionPackagesSelector } from '../../states/features/selectors';
import { filter } from 'lodash';
import { useGetAllPackagesQuery } from '../../api/subscriptionPackages/subscriptionPackagesAPI';
import { setSubscriptionPackages } from '../../states/features/subscriptionPackages/subscriptionPackagesSlice';
import ContentLoader from '../../components/ContentLoader';
import CustomCard2 from '../../components/cards/CustomCard2';
import useRTK from '../../hooks/useRTK';
import SubscriptionPackageSidebar from '../../components/sidebars/SubscriptionPackageSidebar';

const SubscriptionPackages = () => {
    // ############## CONST ############
    const dispatch = useDispatch();
    const RTK = useRTK();

    // ######### Redux state ############
    const { subscriptionPackages } = useSelector(subscriptionPackagesSelector);

    // ############ FUNC #################
    const getSubPackagesByType = () => {
        // => Talent tap
        const talentTapPackages = filter(
            subscriptionPackages,
            ({ type }) => type === 'Talent tap',
        );

        // => Promo connect
        const promoConnectPackages = filter(
            subscriptionPackages,
            ({ type }) => type === 'Promo connect',
        );

        // => Market mingle
        const marketMinglePackages = filter(
            subscriptionPackages,
            ({ type }) => type === 'Market mingle',
        );

        return {
            talentTapPackages,
            promoConnectPackages,
            marketMinglePackages,
        };
    };

    // ############## RTK ############
    const {
        isLoading,
        isSuccess,
        data: packagesData,
    } = useGetAllPackagesQuery();
    const rtkActions = (data) => {
        dispatch(setSubscriptionPackages(data));
    };
    RTK.useRTKFetch(isSuccess, packagesData, rtkActions);

    return (
        <>
            <PageLayout
                head="Subscription packages managements"
                sidebar={<SubscriptionPackageSidebar />}
            >
                {isLoading ? (
                    <>
                        <CustomCard2 height={'78vh'}>
                            <ContentLoader />
                        </CustomCard2>
                    </>
                ) : (
                    <Grid container spacing={4} sx={{ mb: 4 }}>
                        <Grid item sm={12}>
                            <TalentTapPackages
                                subscriptionPackages={
                                    getSubPackagesByType().talentTapPackages
                                }
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <MarketMinglePackages
                                subscriptionPackages={
                                    getSubPackagesByType().marketMinglePackages
                                }
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <PromoConnectPackages
                                subscriptionPackages={
                                    getSubPackagesByType().promoConnectPackages
                                }
                            />
                        </Grid>
                    </Grid>
                )}
            </PageLayout>
        </>
    );
};

export default SubscriptionPackages;
