import React from 'react';
import PageLayout from '../layouts/PageLayout';
import { Box, Grid } from '@mui/material';
import { paginator } from '../../helpers/paginationHelper';
import { useSelector } from 'react-redux';
import { beneficiarySelector } from '../../states/features/selectors';
import { filter, isEmpty, size } from 'lodash';
import CountBadge from '../../components/CountBadge';
import NoContent from '../../components/NoContent';
import CustomPagination from '../../components/paginations/CustomPagination';
import { useParams } from 'react-router-dom';
import BeneficiarySidebar from '../../components/sidebars/BeneficiarySidebar';
import BeneficiaryWidget from '../../components/widgets/BeneficiaryWidget';

const BeneficiaryByStatus = () => {
    // ############## CONST ###################
    const { status } = useParams();

    // ############## Comp state ##################
    const [page, setPage] = React.useState(1);
    const [perPage, setPerPage] = React.useState(10);

    // ############# Redux state #############
    const { beneficiaries } = useSelector(beneficiarySelector);

    // ############## FUNC ####################
    // => handle page change
    const handlePageChange = (page) => {
        setPage(page);
    };
    // => handle per page change
    const handlePerPageChage = (perPage) => {
        setPerPage(perPage);
    };
    // => Beneficiaries by status
    const getBeneficiaryByStatus = () => {
        let filteredBeneficiary = [];
        // => verified
        if (status === 'verified') {
            filteredBeneficiary = filter(
                beneficiaries,
                ({ is_verified }) => is_verified === 1,
            );
        }
        // => requested
        if (status === 'requested') {
            filteredBeneficiary = filter(
                beneficiaries,
                ({ verificationStatus }) => verificationStatus === 'requested',
            );
        }
        // => denied
        if (status === 'denied') {
            filteredBeneficiary = filter(
                beneficiaries,
                ({ verificationStatus }) => verificationStatus === 'denied',
            );
        }
        return filteredBeneficiary;
    };
    const beneficiaryByStatus = getBeneficiaryByStatus();

    // => Pagination data
    const paginatedBeneficiaries = paginator(
        beneficiaryByStatus,
        page,
        perPage,
    );

    return (
        <>
            <PageLayout
                head={`${status} Wazawa max beneficiaries`}
                sidebar={<BeneficiarySidebar />}
            >
                <Grid container spacing={1.5}>
                    <Grid item sm={12}>
                        <CustomPagination
                            head={`${status} beneficiaries`}
                            height={'63.5vh'}
                            borderBottom
                            handlePageChange={handlePageChange}
                            handlePerPageChage={handlePerPageChage}
                            data={paginatedBeneficiaries}
                            action={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box sx={{ ml: 1 }}>
                                        <CountBadge
                                            total={size(beneficiaryByStatus)}
                                            item={'Beneficiaries'}
                                        />
                                    </Box>
                                </Box>
                            }
                        >
                            {isEmpty(beneficiaryByStatus) ? (
                                <>
                                    <NoContent
                                        height={300}
                                        message={`No ${status} beneficiaries`}
                                    />
                                </>
                            ) : (
                                <Grid container spacing={3}>
                                    {paginatedBeneficiaries.data.map(
                                        (beneficiary) => (
                                            <Grid
                                                item
                                                sm={4}
                                                key={beneficiary.id}
                                            >
                                                <BeneficiaryWidget
                                                    beneficiary={beneficiary}
                                                />
                                            </Grid>
                                        ),
                                    )}
                                </Grid>
                            )}
                        </CustomPagination>
                    </Grid>
                </Grid>
            </PageLayout>
        </>
    );
};

export default BeneficiaryByStatus;
