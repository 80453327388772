import { Box, Typography } from '@mui/material';
import React from 'react';
import pro from '../../assets/media/images/promo.jpg';
import { colors } from '../../assets/utils/constants';
import { numFormater } from '../../helpers/numFormater';

const ShopProductWidget = () => {
    return (
        <>
            <Box
                sx={{
                    boxShadow: `1px 1px 5px ${colors.bgColor5}`,
                    borderRadius: 3,
                    overflow: 'hidden',
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'flex-end',
                        height: 120,
                        backgroundImage: `url(${pro})`,
                        backgroundSize: '100% 120px',
                        backgroundRepeat: 'no-repeat',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            px: 2,
                            height: 30,
                            borderTopLeftRadius: 5,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.secondary})`,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: colors.info,
                                fontWeight: 'bold',
                                fontStyle: 'italic',
                            }}
                        >
                            {numFormater(5000000)}
                            <sup
                                style={{
                                    fontSize: 12,
                                    color: colors.success,
                                    fontWeight: 'bold',
                                }}
                            >
                                TZS
                            </sup>
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: 50,
                        background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            color: colors.success,
                        }}
                    >
                        Test product 1
                    </Typography>
                </Box>
            </Box>
        </>
    );
};

export default ShopProductWidget;
