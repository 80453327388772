//! ################## all settings ####################
const GET_ALL_SETTINGS = '/settings';
const GET_JOB_POST_FRAMEWORK = '/pricing-frameworks';
const GET_SMS_BALANCE = '/beem/balance';

export const settingEndpoints = {
    GET_ALL_SETTINGS,
    GET_SMS_BALANCE,
    GET_JOB_POST_FRAMEWORK,
};

//! ################## User status ##################
// -> get all
const GET_USER_STATUS = '/user-statuses';
// -> add status
const ADD_USER_STATUS = '/user-statuses';
// -> update status
const UPDATE_USER_STATUS = '/user-statuses';
// -> activate status
const ACTIVATE_USER_STATUS = '/user-statuses/activate';
// -> deactivate status
const DEACTIVATE_USER_STATUS = '/user-statuses/deactivate';
// -> delete status
const DELETE_USER_STATUS = '/user-statuses';

export const userStatusEndpoints = {
    GET_USER_STATUS,
    ADD_USER_STATUS,
    UPDATE_USER_STATUS,
    ACTIVATE_USER_STATUS,
    DEACTIVATE_USER_STATUS,
    DELETE_USER_STATUS,
};

//! ################## organization types ##################
// -> get all organization types
const GET_ORGANIZATION_TYPES = '/organization-types';
// -> add organization type
const ADD_ORGANIZATION_TYPE = '/organization-types';
// -> update organization type
const UPDATE_ORGANIZATION_TYPE = '/organization-types';
// -> activate organization type
const ACTIVATE_ORGANIZATION_TYPE = '/organization-types/activate';
// -> activate organization type
const DEACTIVATE_ORGANIZATION_TYPE = '/organization-types/deactivate';
// -> delete organization type
const DELETE_ORGANIZATION_TYPE = '/organization-types';

export const organizationTypeEndpoints = {
    GET_ORGANIZATION_TYPES,
    ADD_ORGANIZATION_TYPE,
    UPDATE_ORGANIZATION_TYPE,
    ACTIVATE_ORGANIZATION_TYPE,
    DEACTIVATE_ORGANIZATION_TYPE,
    DELETE_ORGANIZATION_TYPE,
};

//! ################## organization categories ##################
// -> get all organization categories
const GET_ORGANIZATION_CATEGORIES = '/organization-categories';
// -> add organization category
const ADD_ORGANIZATION_CATEGORY = '/organization-categories';
// -> update organization category
const UPDATE_ORGANIZATION_CATEGORY = '/organization-categories';
// -> activate organization category
const ACTIVATE_ORGANIZATION_CATEGORY = '/organization-categories/activate';
const DEACTIVATE_ORGANIZATION_CATEGORY = '/organization-categories/deactivate';
// -> delete organization category
const DELETE_ORGANIZATION_CATEGORY = '/organization-categories';

export const organizationCategoryEndpoints = {
    GET_ORGANIZATION_CATEGORIES,
    ADD_ORGANIZATION_CATEGORY,
    UPDATE_ORGANIZATION_CATEGORY,
    ACTIVATE_ORGANIZATION_CATEGORY,
    DEACTIVATE_ORGANIZATION_CATEGORY,
    DELETE_ORGANIZATION_CATEGORY,
};

//! ################## identification types ##################
// -> get all identification types
const GET_IDENTIFICATION_TYPES = '/identification-types';
// -> add identification type
const ADD_IDENTIFICATION_TYPE = '/identification-types';
// -> update identification type
const UPDATE_IDENTIFICATION_TYPE = '/identification-types';
// -> activate identification type
const ACTIVATE_IDENTIFICATION_TYPE = '/identification-types/activate';
// -> activate identification type
const DEACTIVATE_IDENTIFICATION_TYPE = '/identification-types/deactivate';
// -> delete identification type
const DELETE_IDENTIFICATION_TYPE = '/identification-types';

export const identificationTypesEndpoints = {
    GET_IDENTIFICATION_TYPES,
    ADD_IDENTIFICATION_TYPE,
    UPDATE_IDENTIFICATION_TYPE,
    ACTIVATE_IDENTIFICATION_TYPE,
    DEACTIVATE_IDENTIFICATION_TYPE,
    DELETE_IDENTIFICATION_TYPE,
};

//! ################## verification types ##################
// -> get all verification types
const GET_VERIFICATION_TYPES = '/verification-types';
// -> add verification type
const ADD_VERIFICATION_TYPE = '/verification-types';
// -> update verification type
const UPDATE_VERIFICATION_TYPE = '/verification-types';
// -> activate verification type
const ACTIVATE_VERIFICATION_TYPE = '/verification-types/activate';
// -> activate verification type
const DEACTIVATE_VERIFICATION_TYPE = '/verification-types/deactivate';
// -> delete verification type
const DELETE_VERIFICATION_TYPE = '/verification-types';

export const verificationTypeEndpoints = {
    GET_VERIFICATION_TYPES,
    ADD_VERIFICATION_TYPE,
    UPDATE_VERIFICATION_TYPE,
    ACTIVATE_VERIFICATION_TYPE,
    DEACTIVATE_VERIFICATION_TYPE,
    DELETE_VERIFICATION_TYPE,
};

//! ################## user roles  ##################
// -> get all user role
const GET_USER_ROLES = '/user-roles';
// -> add user role
const ADD_USER_ROLE = '/user-roles';
// -> update user role
const UPDATE_USER_ROLE = '/user-roles';
// -> activate user role
const ACTIVATE_USER_ROLE = '/user-roles/activate';
// -> deactivate user role
const DEACTIVATE_USER_ROLE = '/user-roles/deactivate';
// -> delete user role
const DELETE_USER_ROLE = '/user-roles';

export const userRolesEndpoints = {
    GET_USER_ROLES,
    ADD_USER_ROLE,
    UPDATE_USER_ROLE,
    ACTIVATE_USER_ROLE,
    DEACTIVATE_USER_ROLE,
    DELETE_USER_ROLE,
};

//! ################## job categories  ##################
// -> get all job categories
const GET_JOB_CATEGORIES = '/job-categories';
// -> add job category
const ADD_JOB_CATEGORY = '/job-categories';
// -> update job category
const UPDATE_JOB_CATEGORY = '/job-categories';
// -> activate job category
const ACTIVATE_JOB_CATEGORY = '/job-categories/activate';
// -> deactivate job category
const DEACTIVATE_JOB_CATEGORY = '/job-categories/deactivate';
// -> delete job category
const DELETE_JOB_CATEGORY = '/job-categories';

export const jobCategoriesEndpoints = {
    GET_JOB_CATEGORIES,
    ADD_JOB_CATEGORY,
    UPDATE_JOB_CATEGORY,
    ACTIVATE_JOB_CATEGORY,
    DEACTIVATE_JOB_CATEGORY,
    DELETE_JOB_CATEGORY,
};

//! ################## payment methods  ##################
// -> get all payment method
const GET_PAYMENT_METHODS = '/payment-methods';
// -> add payment method
const ADD_PAYMENT_METHOD = '/payment-methods';
// -> update payment method
const UPDATE_PAYMENT_METHOD = '/payment-methods';
// -> activate payment method
const ACTIVATE_PAYMENT_METHOD = '/payment-methods/activate';
// -> activate payment method
const DEACTIVATE_PAYMENT_METHOD = '/payment-methods/deactivate';
// -> delete payment method
const DELETE_PAYMENT_METHOD = '/payment-methods';

export const paymentMethodsEndpoints = {
    GET_PAYMENT_METHODS,
    ADD_PAYMENT_METHOD,
    UPDATE_PAYMENT_METHOD,
    ACTIVATE_PAYMENT_METHOD,
    DEACTIVATE_PAYMENT_METHOD,
    DELETE_PAYMENT_METHOD,
};

//! ################## group status  ##################
// -> get all group status
const GET_GROUP_STATUSES = '/group-statuses';
// -> add group status
const ADD_GROUP_STATUS = '/group-statuses';
// -> update group status
const UPDATE_GROUP_STATUS = '/group-statuses';
// -> activate group status
const ACTIVATE_GROUP_STATUS = '/group-statuses/activate';
// -> activate group status
const DEACTIVATE_GROUP_STATUS = '/group-statuses/deactivate';
// -> delete group status
const DELETE_GROUP_STATUS = '/group-statuses';

export const groupStatusEndpoints = {
    GET_GROUP_STATUSES,
    ADD_GROUP_STATUS,
    UPDATE_GROUP_STATUS,
    ACTIVATE_GROUP_STATUS,
    DEACTIVATE_GROUP_STATUS,
    DELETE_GROUP_STATUS,
};

//! ################## Sectors ##################
// -> get all sectors
export const GET_SECTORS = '/sectors';
// -> add sector
export const ADD_SECTOR = '/sectors';
// -> update sector
export const UPDATE_SECTOR = '/sectors';
// -> activate sector
export const ACTIVATE_SECTOR = '/sectors/activate';
// -> activate sector
export const DEACTIVATE_SECTOR = '/sectors/deactivate';
// -> delete sector
export const DELETE_SECTOR = '/sectors';

export const sectorsEndpoints = {
    GET_SECTORS,
    ADD_SECTOR,
    UPDATE_SECTOR,
    ACTIVATE_SECTOR,
    DEACTIVATE_SECTOR,
    DELETE_SECTOR,
};

//! ################## department ##################
// -> get all department
export const GET_DEPARTMENTS = '/departments';
// -> add department
export const ADD_DEPARTMENT = '/departments';
// -> update department
export const UPDATE_DEPARTMENT = '/departments';
// -> activate department
export const ACTIVATE_DEPARTMENT = '/departments/activate';
// -> activate department
export const DEACTIVATE_DEPARTMENT = '/departments/deactivate';
// -> delete department
export const DELETE_DEPARTMENT = '/departments';

export const departmentsEndpoints = {
    GET_DEPARTMENTS,
    ADD_DEPARTMENT,
    UPDATE_DEPARTMENT,
    ACTIVATE_DEPARTMENT,
    DEACTIVATE_DEPARTMENT,
    DELETE_DEPARTMENT,
};

//! ################## Sections ##################
// -> get all sections
const GET_SECTIONS = '/sections';
// -> add sections
const ADD_SECTION = '/sections';
// -> update sections
const UPDATE_SECTION = '/sections';
// -> activate sections
const ACTIVATE_SECTION = '/sections/activate';
// -> deactivate sections
const DEACTIVATE_SECTION = '/sections/deactivate';
// -> delete sections
const DELETE_SECTION = '/sections';

export const sectionsEndpoints = {
    GET_SECTIONS,
    ADD_SECTION,
    UPDATE_SECTION,
    ACTIVATE_SECTION,
    DEACTIVATE_SECTION,
    DELETE_SECTION,
};

//! ################## qualifications award ##################
// -> get all qualifications award
export const GET_QUALIFICATION_AWARDS = '/qualifications/award-levels';
// -> add qualification award
export const ADD_QUALIFICATION_AWARD = '/qualifications/award-levels';
// -> update qualification award
export const UPDATE_QUALIFICATION_AWARD = '/qualifications/award-levels';
// -> activate qualification award
export const ACTIVATE_QUALIFICATION_AWARD =
    '/qualifications/award-levels/activate';
// -> activate qualification award
export const DEACTIVATE_QUALIFICATION_AWARD =
    '/qualifications/award-levels/deactivate';
// -> delete qualification award
export const DELETE_QUALIFICATION_AWARD = '/qualifications/award-levels';

export const qualificationAwardEndpoints = {
    GET_QUALIFICATION_AWARDS,
    ADD_QUALIFICATION_AWARD,
    UPDATE_QUALIFICATION_AWARD,
    ACTIVATE_QUALIFICATION_AWARD,
    DEACTIVATE_QUALIFICATION_AWARD,
    DELETE_QUALIFICATION_AWARD,
};

//! ################## Education level ##################
// -> get all Education level
const GET_EDUCATION_LEVELS = '/qualifications/education-levels';
// -> add Education level
const ADD_EDUCATION_LEVEL = '/qualifications/education-levels';
// -> update Education level
const UPDATE_EDUCATION_LEVEL = '/qualifications/education-levels';
// -> activate Education level
const ACTIVATE_EDUCATION_LEVEL = '/qualifications/education-levels/activate';
const DEACTIVATE_EDUCATION_LEVEL =
    '/qualifications/education-levels/deactivate';
// -> delete Education level
export const DELETE_EDUCATION_LEVEL = '/qualifications/education-levels';

export const educationLevelEndpoints = {
    GET_EDUCATION_LEVELS,
    ADD_EDUCATION_LEVEL,
    UPDATE_EDUCATION_LEVEL,
    ACTIVATE_EDUCATION_LEVEL,
    DEACTIVATE_EDUCATION_LEVEL,
    DELETE_EDUCATION_LEVEL,
};

//! ################## Experience level ##################
// -> get all experience-levels
const GET_EXPERIENCE_LEVELS = '/qualifications/experience-levels';
// -> add experience-levels
const ADD_EXPERIENCE_LEVEL = '/qualifications/experience-levels';
// -> update experience-levels
const UPDATE_EXPERIENCE_LEVEL = '/qualifications/experience-levels';
// -> activate experience-levels
const ACTIVATE_EXPERIENCE_LEVEL = '/qualifications/experience-levels/activate';
const DEACTIVATE_EXPERIENCE_LEVEL =
    '/qualifications/experience-levels/deactivate';
// -> delete experience-levels
const DELETE_EXPERIENCE_LEVEL = '/qualifications/experience-levels';

export const experienceLevelEndpoints = {
    GET_EXPERIENCE_LEVELS,
    ADD_EXPERIENCE_LEVEL,
    UPDATE_EXPERIENCE_LEVEL,
    ACTIVATE_EXPERIENCE_LEVEL,
    DEACTIVATE_EXPERIENCE_LEVEL,
    DELETE_EXPERIENCE_LEVEL,
};
