import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import CustomCard2 from '../../components/cards/CustomCard2';
import PageLayout from '../layouts/PageLayout';
import ReportButtonWidget from '../../components/widgets/ReportButtonWidget';
import ReportDialog from '../../components/Dialogies/ReportDialog';
import JobReport from './reports/JobReport';
import RevenueCard2 from '../../components/dash/RevenueCard2';
import RevenueFilter from '../../components/filters/RevenueFilter';

const ReportAndAnalytics = () => {
    // ############### Comp State #################
    const [openDialog, setOpenDialog] = useState(false);
    const [actionHead, setActionHead] = useState('');
    // const [actionType, setActionType] = useState('');

    // ############## FUNC #################
    // => Handle dialogy open
    const handleDialogOpen = (action, head) => {
        // setActionType(action);
        setActionHead(head);
        setOpenDialog(true);
    };
    // => Handle dialogy close
    const handleDialogClose = () => {
        setActionHead('');
        setOpenDialog(false);
    };

    const reports = [
        {
            id: 1,
            title: 'Jobs',
            action: handleDialogOpen,
        },
        {
            id: 2,
            title: 'Trainings',
            action: handleDialogOpen,
        },
        {
            id: 3,
            title: 'Students',
            action: handleDialogOpen,
        },
        {
            id: 4,
            title: 'Applicants',
            action: handleDialogOpen,
        },
        {
            id: 5,
            title: 'Transactions',
            action: handleDialogOpen,
        },
    ];

    return (
        <>
            {/* ############# DIALOG ############### */}
            <ReportDialog
                isConfirm
                noDropClose
                openDialog={openDialog}
                customWidth={'md'}
                handleDialogClose={handleDialogClose}
                head={actionHead}
            >
                <JobReport />
            </ReportDialog>

            <PageLayout head={'Analysis and reports'} noSidebar>
                <Grid container spacing={2}>
                    <Grid item sm={8}>
                        <CustomCard2
                            head={'Finance revenue overview'}
                            borderBottom
                            action={
                                <Box sx={{ display: 'flex' }}>
                                    <RevenueFilter width={180} />
                                </Box>
                            }
                        >
                            <RevenueCard2 />
                        </CustomCard2>
                    </Grid>
                    <Grid item sm={4}>
                        <CustomCard2 head={'Reports'}>
                            <Grid container spacing={2.5}>
                                {reports.map((report, index) => (
                                    <Grid item sm={6}>
                                        <ReportButtonWidget report={report} />
                                    </Grid>
                                ))}
                            </Grid>
                        </CustomCard2>
                    </Grid>
                </Grid>
                <Grid container mt={2}></Grid>
            </PageLayout>
        </>
    );
};

export default ReportAndAnalytics;
