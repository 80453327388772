import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { borderRadius, colors } from '../../assets/utils/constants';

const Card = styled(Box)({
    borderRadius: 15,
    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
    border: `1px solid ${colors.bgColor3}`,
});

const CardHeader = styled(Box)({
    'display': 'flex',
    'justifyContent': 'space-between',
    'alignItems': 'center',
    'paddingTop': 12,
    'paddingBottom': 12,
    'paddingLeft': 15,
    'paddingRight': 15,
    'position': 'relative !important',
    '.title': {
        'fontSize': 16,
        'color': colors.primary,
        '::before': {
            content: "''",
            width: 90,
            height: 1,
            background: colors.primary,
            position: 'absolute',
            bottom: 0,
        },
    },
});

const CardBody = styled(Box)({
    padding: 0,
});

const CustomCard4 = ({ action, title, height, children }) => {
    return (
        <React.Fragment>
            <Card sx={{ borderRadius: borderRadius.br1 }}>
                {title && (
                    <CardHeader>
                        <Typography className="title">{title}</Typography>
                        <Box>{action}</Box>
                    </CardHeader>
                )}
                <CardBody sx={{ height: height ? height : '100%' }}>
                    {children}
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default CustomCard4;
