import { createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { removeArrObj, updateArrObj } from '../../../helpers/arrayHelpers';

const initialState = {
    promotions: [],
    promotionDetails: {},
    promoSubscriptionDetails: {},
    checkPaymentStatus: 0,
};

// !########## SLICE #############
const promotionsSlice = createSlice({
    name: 'promotionsSlice',
    initialState,
    reducers: {
        setPromotions: (state, actions) => {
            state.promotions = actions.payload;
        },
        setPromotionDetails: (state, actions) => {
            state.promotionDetails = actions.payload;
        },
        addPromotion: (state, actions) => {
            let promotion = actions.payload;
            isEmpty(state.promotions)
                ? (state.promotions = [promotion, ...[]])
                : (state.promotions = [promotion, ...state.promotions]);
        },
        updatePromotion: (state, actions) => {
            let newPromotion = actions.payload;
            let promotions = updateArrObj('id', newPromotion, state.promotions);
            state.promotionDetails = actions.payload;
            state.promotions = promotions;
        },
        removePromotion: (state, actions) => {
            let payload = actions.payload;
            let promotions = removeArrObj('id', payload, state.promotions);
            state.promotionDetails = {};
            state.promotions = promotions;
        },
        setPromoSubscriptionDetails: (state, actions) => {
            state.promoSubscriptionDetails = actions.payload;
        },
        addCheckPaymentStatus: (state, action) => {
            state.checkPaymentStatus = action.payload;
        },
    },
});

export const {
    setPromotions,
    setPromotionDetails,
    addPromotion,
    updatePromotion,
    removePromotion,
    addCheckPaymentStatus,
    setPromoSubscriptionDetails,
} = promotionsSlice.actions;
export default promotionsSlice.reducer;
