import { Grid } from '@mui/material';
import React from 'react';
import ShopProductWidget from '../../../components/widgets/ShopProductWidget';

const ShopProducts = ({ shopProducts }) => {
    return (
        <>
            <Grid container spacing={4} padding={3}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => (
                    <Grid item sm={4} key={index}>
                        <ShopProductWidget />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default ShopProducts;
