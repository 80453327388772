import React from 'react';

// ->MUI import
import { Box, Button, Grid } from '@mui/material';

// -> component imports
import InfoBoxes from '../components/InfoBoxes';
import PendingActivities from '../components/PendingActivities';
import DashboardLayout from './layouts/DashboardLayout';
import CustomCard2 from '../components/cards/CustomCard2';
import RevenueCard from '../components/dash/RevenueCard';
import { ArrowCircleRightOutlined } from '@mui/icons-material';
import TodayActivities from '../components/dash/TodayActivities';
import JobStatsWidget from '../components/widgets/JobStatsWidget';
import PTStatsWidget from '../components/widgets/PTStatsWidget';
import { Link } from 'react-router-dom';

// !################### MAIN FUNC ####################
const Dashboard = () => {
    return (
        <>
            <DashboardLayout>
                <Grid container spacing={2}>
                    {/* ############### INFO BOXES ################## */}
                    <Grid item sm={6}>
                        <Grid container spacing={2} sx={{ height: '100%' }}>
                            <InfoBoxes />
                        </Grid>
                    </Grid>
                    <Grid item sm={3}>
                        <JobStatsWidget />
                    </Grid>
                    <Grid item sm={3}>
                        <PTStatsWidget />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    {/* ############### PENDING ACTIVITIE ################## */}
                    <Grid item sm={6}>
                        <CustomCard2 bodyPadding={0}>
                            <PendingActivities />
                        </CustomCard2>
                    </Grid>
                    {/* ############### REVENUE ################## */}
                    <Grid item sm={6}>
                        <CustomCard2
                            height={'39.2vh'}
                            head={'Revenue overview'}
                            action={
                                <Box sx={{ display: 'flex' }}>
                                    <Link to={'/finance'}>
                                        <Button
                                            endIcon={
                                                <ArrowCircleRightOutlined />
                                            }
                                            size="small"
                                            className="app-btn"
                                            variant="outlined"
                                        >
                                            Manage
                                        </Button>
                                    </Link>
                                </Box>
                            }
                        >
                            <RevenueCard />
                        </CustomCard2>
                    </Grid>
                </Grid>
                {/* ############### TODAY ACTIVITIES ################## */}
                <Grid container spacing={2} sx={{ mt: 0.5 }}>
                    <Grid item sm={12}>
                        <TodayActivities />
                    </Grid>
                </Grid>
            </DashboardLayout>
        </>
    );
};

export default Dashboard;
