import React, { useEffect } from 'react';
import { MessageOutlined } from '@mui/icons-material';
import { Typography, Badge, CircularProgress } from '@mui/material';
import { settingsSelector } from '../../states/features/selectors';
import { useGetBeemBalanceQuery } from '../../api/settings/settingAPI';
import { setSmsBalance } from '../../states/features/settings/settingsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../assets/utils/constants';

const BeemSMSBalanceWidget = () => {
    // ######### COMP State #######
    const dispatch = useDispatch();
    const { smsBalance } = useSelector(settingsSelector);

    // ######## RTK ##############
    // => Get balance
    const {
        isLoading,
        isSuccess,
        isError,
        data: balanceData,
    } = useGetBeemBalanceQuery();

    // ########### useEffect ############
    useEffect(() => {
        let isSubscribed = true;
        if (isSubscribed) {
            if (isSuccess) {
                dispatch(setSmsBalance(balanceData.data));
            }
        }
        return () => {
            isSubscribed = false;
        };
    }, [isSuccess, isError]);

    return (
        <div>
            <Badge
                color={smsBalance < 25 ? 'error' : 'info'}
                badgeContent={smsBalance}
                max={999}
                showZero
                sx={{
                    'display': 'flex',
                    'flexDirection': 'column',
                    'alignItems': 'center',
                    '& .MuiBadge-badge': {
                        bgcolor: smsBalance < 25 ? 'red' : colors.info,
                        fontWeight: 'bold',
                        height: 15,
                    },
                }}
            >
                {isLoading ? (
                    <CircularProgress color="secondary" size={24} />
                ) : (
                    <MessageOutlined className="icon" />
                )}

                <Typography
                    sx={{
                        fontSize: 10,
                        fontWeight: 'bold',
                        ml: 0.5,
                    }}
                >
                    SMS Balance
                </Typography>
            </Badge>
        </div>
    );
};

export default BeemSMSBalanceWidget;
