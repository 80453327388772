import { Box, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import { VerticalBarChart } from '../charts/VerticalBarChart';
import { ArrowRightAlt } from '@mui/icons-material';

const RevenueCard2 = () => {
    const revenueResources = [
        {
            id: 1,
            name: 'Promo connect',
            total: '5000,000',
        },
        {
            id: 3,
            name: 'Talent tap',
            total: '5000,000',
        },
        {
            id: 4,
            name: 'Market mingle',
            total: '5000,000',
        },
        {
            id: 2,
            name: 'Job posting',
            total: '5000,000',
        },
    ];
    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <Box
                        sx={{
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 45,
                                fontWeight: 'bold',
                                color: colors.info,
                            }}
                        >
                            500,000,000
                            <sup
                                style={{
                                    fontSize: 15,
                                    color: colors.success,
                                    fontWeight: 'bold',
                                }}
                            >
                                TZS
                            </sup>
                        </Typography>
                        <Typography sx={{ color: colors.primary }}>
                            Total revenue
                        </Typography>
                    </Box>
                    <Box>
                        <VerticalBarChart
                            title={'Revenue stats'}
                            height={'42vh'}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                {revenueResources.map((resource) => (
                    <Grid item sm={3} key={resource.id}>
                        <Box
                            sx={{
                                'p': 1,
                                'mt': 1,
                                'borderRadius': 2,
                                'borderLeft': `4px solid ${colors.info}`,
                                'background': `linear-gradient(90deg, ${colors.secondary}, ${colors.bgColor2})`,
                                ':hover': {
                                    '& .action-btn': {
                                        transition: `.3s ease-in-out all`,
                                        border: `1px solid ${colors.warning}`,
                                    },
                                },
                            }}
                        >
                            <Typography
                                sx={{
                                    fontSize: 18,
                                    fontWeight: 'bold',
                                    color: colors.warning,
                                }}
                            >
                                {resource.total}
                                <sup
                                    style={{
                                        fontSize: 12,
                                        color: colors.success,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    TZS
                                </sup>
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: 15,
                                    opacity: 0.8,
                                }}
                            >
                                {resource.name}
                            </Typography>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    mt: -3,
                                }}
                            >
                                <IconButton
                                    color="info"
                                    size="small"
                                    className="action-btn"
                                    sx={{
                                        p: 0,
                                        transition: `.3s ease-in-out all`,
                                        border: `1px solid ${colors.secondary}`,
                                        background: `linear-gradient(90deg, ${colors.bgColor3}, ${colors.bgColor2})`,
                                    }}
                                >
                                    <ArrowRightAlt />
                                </IconButton>
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </>
    );
};

export default RevenueCard2;
