import React from 'react';
import { colors } from '../../assets/utils/constants';
import {
    Avatar,
    Box,
    Chip,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from '@mui/material';
import {
    LockClock,
    Pending,
    Person,
    Place,
    RequestQuote,
    Verified,
} from '@mui/icons-material';
import moment from 'moment/moment';
import useCurrency from '../../hooks/useCurrency';
import strHelper from '../../helpers/strHelper';

// !############### MAIN FUNC #################
const JobTopInfoWidget = ({ jobDetails }) => {
    // ########### CONST ##############
    const STR = strHelper();
    const currency = useCurrency();
    const jobStatusColor = () => {
        if (jobDetails.status === 'draft') {
            return colors.warning;
        } else if (jobDetails.status === 'published') {
            return colors.success;
        }
    };
    return (
        <React.Fragment>
            <Box
                className="neo-buldge"
                sx={{
                    'borderRadius': 4,
                    'overflow': 'hidden',
                    'marginBottom': 2,
                    'transition': '.2s all ease-in-out',
                    'border': `1px solid ${colors.secondary}`,
                    'borderLeft': `4px solid ${jobStatusColor()}`,
                    'background': `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3}) !important`,
                    '& .more': {
                        transition: '.2s all ease-in-out',
                        background: `linear-gradient(180deg, ${colors.bgColor2}, ${colors.bgColor4})`,
                    },
                    ':hover': {
                        'boxShadow': '4p 4px 4px 4px rgba(46, 119, 174, .8)',
                        'transition': '.2s all ease-in-out',
                        '& .more': {
                            marginLeft: 1,
                            transition: '.2s all ease-in-out',
                        },
                    },
                }}
            >
                <Box>
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        columnSpacing={2}
                    >
                        <Grid item sm={6}>
                            <List
                                sx={{
                                    width: '100%',
                                    mb: 0,
                                    pb: 0,
                                }}
                            >
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar
                                            alt="logo"
                                            src={
                                                jobDetails.organization
                                                    .organization_logo
                                            }
                                            variant="square"
                                            sx={{
                                                height: 50,
                                                width: 50,
                                                borderRadius: 2,
                                                p: .5,
                                                background: `linear-gradient(180deg, ${colors.bgColor3}, ${colors.bgColor4})`,
                                                img: {
                                                    objectFit: 'contain',
                                                },
                                            }}
                                        />
                                    </ListItemAvatar>
                                    <ListItemText
                                        disableTypography
                                        sx={{
                                            '.MuiListItemText-primary': {
                                                fontSize: 17,
                                                fontWeight: 'bold',
                                            },
                                        }}
                                        primary={
                                            <Typography
                                                sx={{
                                                    fontSize: 18,
                                                    fontWeight: 'bold',
                                                    opacity: 0.7,
                                                    ml: 1,
                                                }}
                                            >
                                                {STR.strCapitalizeFirstChar(
                                                    jobDetails.title,
                                                )}
                                            </Typography>
                                        }
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{
                                                        display: 'inline',
                                                    }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {jobDetails.category_name}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item sm={2.5}>
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    mt: 2,
                                    p: 1,
                                    borderRadius: 3,
                                    borderBottom: '1px solid silver',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: 12,
                                        color: colors.primary,
                                    }}
                                >
                                    Applicants
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 13,
                                        color: colors.warning,
                                        fontWeight: 'bold',
                                    }}
                                    noWrap
                                >
                                    56
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item sm={2.5}>
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    mt: 2,
                                    p: 1,
                                    borderRadius: 3,
                                    borderBottom: '1px solid silver',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontWeight: 'bold',
                                        fontSize: 12,
                                        color: colors.primary,
                                    }}
                                >
                                    Posted
                                </Typography>
                                <Typography
                                    sx={{
                                        fontSize: 13,
                                        color: colors.info,
                                        fontWeight: 'bold',
                                    }}
                                    noWrap
                                >
                                    {moment(jobDetails.created_at).fromNow()}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        'p': 1,
                        '& .chip': {
                            border: `1px solid ${colors.bgColor5}`,
                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2}) !important`,
                        },
                    }}
                >
                    <Chip
                        icon={<Place />}
                        label={jobDetails.location}
                        sx={{ mr: 1 }}
                        variant="outlined"
                        size="small"
                        className="chip"
                    />
                    <Chip
                        icon={<Person />}
                        label={jobDetails.isFor}
                        sx={{ mr: 1 }}
                        variant="outlined"
                        size="small"
                        className="chip"
                    />
                    <Chip
                        icon={<LockClock />}
                        label={jobDetails.job_type.replace(/[^a-zA-Z ]/g, ' ')}
                        sx={{ mr: 1, textTransform: 'capitalize' }}
                        variant="outlined"
                        size="small"
                        className="chip"
                    />
                    {jobDetails.salary_scale && (
                        <Chip
                            icon={<RequestQuote />}
                            label={`Salary scale: ${jobDetails.salary_scale}`}
                            sx={{ mr: 1 }}
                            variant="outlined"
                            size="small"
                            className="chip"
                        />
                    )}
                    {jobDetails.salary && (
                        <Chip
                            icon={<RequestQuote />}
                            label={`Salary: ${currency.formatCurrency(
                                'TZS',
                                jobDetails.salary,
                            )}`}
                            sx={{ mr: 1 }}
                            variant="outlined"
                            size="small"
                            className="chip"
                        />
                    )}
                    {jobDetails.status === 'draft' ? (
                        <>
                            <Chip
                                icon={<Pending />}
                                label="Drafted"
                                sx={{ mr: 1 }}
                                size="small"
                                color="warning"
                            />
                        </>
                    ) : jobDetails.status === 'published' ? (
                        <>
                            <Chip
                                icon={<Verified />}
                                label="Published"
                                sx={{ mr: 1 }}
                                size="small"
                                color="success"
                            />
                        </>
                    ) : (
                        <></>
                    )}
                </Box>
            </Box>
        </React.Fragment>
    );
};

export default JobTopInfoWidget;
