import { configureStore } from '@reduxjs/toolkit';
import { RTKmiddleware } from './RTKmiddlewares';

// => SLICE reduces
import authReducer from '../features/auth/authSlice';
import settingsReducer from '../features/settings/settingsSlice';
import userStatusReducer from '../../states/features/settings/userStatusSlice';
import organizationsReducer from '../features/organizations/organizationsSlice';
import userRolesReducer from '../features/settings/userRolesSlice';
import verificationTypesReducer from '../features/settings/verificationTypesSlice';
import groupStatusReducer from '../features/settings/groupStatusSlice';
import sectorsReducer from '../features/settings/sectorsSlice';
import identificationTypesReducer from '../features/settings/identificationTypesSlice';
import jobCategoriesReducer from '../features/settings/jobCategoriesSlice';
import organizationCategoriesReducer from '../features/settings/organizationCategoriesSlice';
import organizationTypesReducer from '../features/settings/organizationTypesSlice';
import departimentsReducer from '../features/settings/departmentsSlice';
import educationLevelsReducer from '../features/settings/educationLevelsSlice';
import experienceLevelsReducer from '../features/settings/experienceLevelsSlice';
import qualificationAwardsReducer from '../features/settings/qualificationAwardsSlice';
import sectionsReducer from '../features/settings/sectionsSlice';
import blogsReducer from '../features/blogs/blogsSlice';
import promotionsReducer from '../features/promotions/promotionsSlice';
import promotionCustomersReducer from '../features/promotions/promotionCustomersSlice';
import faqsReducer from '../features/faqs/faqsSlice';
import jobsReducer from '../features/jobs/jobsSlice';
import employersReducer from '../features/employers/employersSlice';
import beneficiaryReducer from '../features/beneficiary/beneficiarySlice';
import helpCenterReducer from '../features/helpCenter/helpCenterSlice';
import shopsReducer from '../features/shops/shopsSlice';
import studentsReducer from '../features/students/studentsSlice';
import subscriptionPackagesReducer from '../features/subscriptionPackages/subscriptionPackagesSlice';
import practicalTrainingsReducer from '../features/practicalTraining/practicalTrainingsSlice';
import notificationsReducer from '../features/notifications/notificationsSlice';

// => RTK reducers
import { authAPI } from '../../api/auth/authAPI';
import { settingAPI } from '../../api/settings/settingAPI';
import { userStatusAPI } from '../../api/settings/userStatusAPI';
import { organizationAPI } from '../../api/organizations/organizationAPI';
import { userRolesAPI } from '../../api/settings/userRolesAPI';
import { verificationTypesAPI } from '../../api/settings/verificationTypesAPI';
import { groupStatusAPI } from '../../api/settings/groupStatusAPI';
import paymentMethodsReducer from '../features/settings/paymentMethodsSlice';
import { paymentMethodsAPI } from '../../api/settings/paymentMethodsAPI';
import { sectorsAPI } from '../../api/settings/sectorsAPI';
import { identificationTypesAPI } from '../../api/settings/identificationTypesAPI';
import { jobCategoriesAPI } from '../../api/settings/jobCategoriesAPI';
import { organizationCategoriesAPI } from '../../api/settings/organizationCategoriesAPI';
import { organizationTypesAPI } from '../../api/settings/organizationTypesAPI';
import { departmentsAPI } from '../../api/settings/departmentsAPI';
import { educationLevelAPI } from '../../api/settings/educationLevelAPI';
import { experienceLevelAPI } from '../../api/settings/experienceLevelAPI';
import { qualificationAwardsAPI } from '../../api/settings/qualificationAwardsAPI';
import { sectionsAPI } from '../../api/settings/sectionsAPI';
import { blogsAPI } from '../../api/blogs/blogsAPI';
import { promotionsAPI } from '../../api/promotions/promotionsAPI';
import { promotionsCustomersAPI } from '../../api/promotions/promotionsCustomersAPI';
import { faqsAPI } from '../../api/faqs/faqsAPI';
import { jobAPI } from '../../api/job/jobAPI';
import { employersAPI } from '../../api/employers/employersAPI';
import { helpCenterAPI } from '../../api/helpCenter/helpCenterAPI';
import { beneficiaryAPI } from '../../api/beneficiaries/beneficiaryAPI';
import { shopAPI } from '../../api/shops/shopAPI';
import { studentsAPI } from '../../api/students/studentsAPI';
import { subscriptionPackagesAPI } from '../../api/subscriptionPackages/subscriptionPackagesAPI';
import { practicalTrainingsAPI } from '../../api/practicalTrainings/practicalTrainingsAPI';
import { notificationsAPI } from '../../api/notifications/notificationsAPI';

export const store = configureStore({
    reducer: {
        // => Auth
        [authAPI.reducerPath]: authAPI.reducer,

        // => Settings
        [settingAPI.reducerPath]: settingAPI.reducer,
        [userStatusAPI.reducerPath]: userStatusAPI.reducer,
        [userRolesAPI.reducerPath]: userRolesAPI.reducer,
        [verificationTypesAPI.reducerPath]: verificationTypesAPI.reducer,
        [groupStatusAPI.reducerPath]: groupStatusAPI.reducer,
        [paymentMethodsAPI.reducerPath]: paymentMethodsAPI.reducer,
        [sectorsAPI.reducerPath]: sectorsAPI.reducer,
        [identificationTypesAPI.reducerPath]: identificationTypesAPI.reducer,
        [jobCategoriesAPI.reducerPath]: jobCategoriesAPI.reducer,
        [organizationCategoriesAPI.reducerPath]:
            organizationCategoriesAPI.reducer,
        [organizationTypesAPI.reducerPath]: organizationTypesAPI.reducer,
        [departmentsAPI.reducerPath]: departmentsAPI.reducer,
        [educationLevelAPI.reducerPath]: educationLevelAPI.reducer,
        [experienceLevelAPI.reducerPath]: experienceLevelAPI.reducer,
        [qualificationAwardsAPI.reducerPath]: qualificationAwardsAPI.reducer,
        [sectionsAPI.reducerPath]: sectionsAPI.reducer,
        [blogsAPI.reducerPath]: blogsAPI.reducer,
        [faqsAPI.reducerPath]: faqsAPI.reducer,
        [helpCenterAPI.reducerPath]: helpCenterAPI.reducer,
        [subscriptionPackagesAPI.reducerPath]: subscriptionPackagesAPI.reducer,
        [notificationsAPI.reducerPath]: notificationsAPI.reducer,

        // => Actions
        [organizationAPI.reducerPath]: organizationAPI.reducer,
        [promotionsAPI.reducerPath]: promotionsAPI.reducer,
        [promotionsCustomersAPI.reducerPath]: promotionsCustomersAPI.reducer,
        [jobAPI.reducerPath]: jobAPI.reducer,
        [shopAPI.reducerPath]: shopAPI.reducer,
        [practicalTrainingsAPI.reducerPath]: practicalTrainingsAPI.reducer,

        // => Users
        [employersAPI.reducerPath]: employersAPI.reducer,
        [beneficiaryAPI.reducerPath]: beneficiaryAPI.reducer,
        [studentsAPI.reducerPath]: studentsAPI.reducer,

        // => Auth
        authReducer,

        // => Settings
        settingsReducer,
        userStatusReducer,
        userRolesReducer,
        verificationTypesReducer,
        groupStatusReducer,
        paymentMethodsReducer,
        sectorsReducer,
        jobCategoriesReducer,
        identificationTypesReducer,
        organizationCategoriesReducer,
        organizationTypesReducer,
        departimentsReducer,
        educationLevelsReducer,
        experienceLevelsReducer,
        qualificationAwardsReducer,
        sectionsReducer,
        blogsReducer,
        faqsReducer,
        helpCenterReducer,
        subscriptionPackagesReducer,
        notificationsReducer,

        // => Actions
        organizationsReducer,
        promotionsReducer,
        promotionCustomersReducer,
        jobsReducer,
        shopsReducer,
        practicalTrainingsReducer,

        // => Users
        employersReducer,
        beneficiaryReducer,
        studentsReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(RTKmiddleware),
});
