import React, { useState } from 'react';
import CustomCard2 from '../../../components/cards/CustomCard2';
import { Button, Grid } from '@mui/material';
import PromotionSubscriptionPayment from '../CRUD/PromotionSubscriptionPayment';
import CustomDialog from '../../../components/Dialogies/CustomDialog';
import PublishPromotion from '../CRUD/PublishPromotion';
import { SubscriptOutlined } from '@mui/icons-material';
import CustomSubmitButton from '../../../components/Forms/CustomSubmitButton';
import PromotionSubscription from '../CRUD/PromotionSubscription';

const PromoActionButtons = ({ promotion }) => {
    // ############ Comp states ###################
    const [openDialog, setOpenDialogy] = useState(false);
    const [actionType, setActionType] = useState('');

    // ############# Redux state ##################
    const currentSubscription = promotion?.currSubscription;

    // -> Dialogy closer
    const handleDialogOpen = (actionType) => {
        setOpenDialogy(true);
        setActionType(actionType);
    };

    // -> Dialogy closer
    const handleDialogClose = () => {
        setOpenDialogy(false);
    };

    return (
        <div>
            <CustomDialog
                head={
                    actionType === 'payment'
                        ? 'Promotion subscription payment'
                        : actionType === 'publish'
                        ? 'Publish promotion confimation'
                        : actionType === 'history'
                        ? 'Promotion subscription history'
                        : actionType === 'subscribe'
                        ? 'Promotion subscription'
                        : ''
                }
                openDialog={openDialog}
                customWidth={actionType === 'payment' ? 'md' : 'sm'}
                handleDialogClose={handleDialogClose}
            >
                {actionType === 'payment' ? (
                    <PromotionSubscriptionPayment
                        promotion={promotion}
                        handleDialogClose={handleDialogClose}
                    />
                ) : actionType === 'publish' ? (
                    <PublishPromotion
                        promotion={promotion}
                        handleDialogClose={handleDialogClose}
                    />
                ) : actionType === 'history' ? (
                    ''
                ) : actionType === 'subscribe' ? (
                    <>
                        <PromotionSubscription
                            promotion={promotion}
                            handleDialogClose={handleDialogClose}
                        />
                    </>
                ) : (
                    ''
                )}
            </CustomDialog>
            <CustomCard2 height={130} head={'Action buttons'}>
                <Grid container spacing={2}>
                    {currentSubscription.status === 'inactive' &&
                        currentSubscription?.transaction?.status ===
                            'success' && (
                            <Grid item xs>
                                <CustomSubmitButton
                                    btnColor="success"
                                    sx={{ py: 2 }}
                                    onClick={() => {
                                        handleDialogOpen('publish');
                                    }}
                                >
                                    Publish promotion
                                </CustomSubmitButton>
                            </Grid>
                        )}
                    {currentSubscription === false ? (
                        <Grid item xs>
                            <CustomSubmitButton
                                onClick={() => {
                                    handleDialogOpen('subscribe');
                                }}
                                sx={{ py: 2 }}
                            >
                                Subscribe now !
                            </CustomSubmitButton>
                        </Grid>
                    ) : (
                        currentSubscription?.transaction?.status !==
                            'success' && (
                            <Grid item xs>
                                <CustomSubmitButton
                                    btnColor="warning"
                                    onClick={() => {
                                        handleDialogOpen('payment');
                                    }}
                                    sx={{ py: 2 }}
                                >
                                    Pay subscription
                                </CustomSubmitButton>
                            </Grid>
                        )
                    )}
                    <Grid item xs>
                        <CustomSubmitButton
                            sx={{ py: 2 }}
                            btnColor={'info'}
                            onClick={() => {
                                handleDialogOpen('history');
                            }}
                        >
                            Subscription history
                        </CustomSubmitButton>
                    </Grid>
                </Grid>
            </CustomCard2>
        </div>
    );
};

export default PromoActionButtons;
