import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { adminBaseURL } from '../baseUrl/baseURL';
import { authEndpoints } from './authEndpoints';
import { headers } from '../baseUrl/setHeaders';

export const authAPI = createApi({
    reducerPath: 'authAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: adminBaseURL,
    }),
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (payload) => ({
                url: `${authEndpoints.LOGIN}`,
                method: 'POST',
                body: payload,
            }),
        }),
        updateProfile: builder.mutation({
            query: (payload) => ({
                url: `${authEndpoints.UPDATE_PROFILE}`,
                method: `PUT`,
                body: payload,
                headers: headers,
            }),
        }),
        changePassword: builder.mutation({
            query: (payload) => ({
                url: `${authEndpoints.CHANGE_PASSWORD}`,
                method: `PUT`,
                body: payload,
                headers: headers,
            }),
        }),
        logout: builder.mutation({
            query: () => ({
                url: `${authEndpoints.LOGOUT}`,
                method: 'POST',
                headers: headers,
            }),
        }),
    }),
});

export const {
    useLoginMutation,
    useLogoutMutation,
    useUpdateProfileMutation,
    useChangePasswordMutation,
} = authAPI;
