import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    subscriptionPackages: [],
};

// !########## SLICE #############
const subscriptionPackagesSlice = createSlice({
    name: 'subscriptionPackagesSlice',
    initialState,
    reducers: {
        setSubscriptionPackages: (state, actions) => {
            state.subscriptionPackages = actions.payload;
        },
    },
});

export const { setSubscriptionPackages } = subscriptionPackagesSlice.actions;
export default subscriptionPackagesSlice.reducer;
