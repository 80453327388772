import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { colors } from '../../assets/utils/constants';
import { VerticalBarChart } from '../charts/VerticalBarChart';

const RevenueCard = () => {
    const revenueResources = [
        {
            id: 1,
            name: 'Promotions',
            total: '5000,000',
        },
        {
            id: 2,
            name: 'Job posting fee',
            total: '5000,000',
        },
        {
            id: 3,
            name: 'Fundi subscriptions',
            total: '5000,000',
        },
        {
            id: 4,
            name: 'Shops subscriptions',
            total: '5000,000',
        },
    ];
    return (
        <>
            <Grid container spacing={2}>
                <Grid item sm={9}>
                    <Box
                        sx={{
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 25,
                                fontWeight: 'bold',
                                color: colors.info
                            }}
                        >
                            500,000,000
                            <sup
                                style={{
                                    fontSize: 15,
                                    color: colors.success,
                                    fontWeight: 'bold',
                                }}
                            >
                                TZS
                            </sup>
                        </Typography>
                        <Typography sx={{ color: colors.primary }}>
                            Total revenue
                        </Typography>
                    </Box>
                    <Box>
                        <VerticalBarChart title={'Revenue stats'} />
                    </Box>
                </Grid>
                <Grid item sm={3}>
                    <Grid container spacing={1}>
                        {revenueResources.map((resource) => (
                            <Grid item sm={12} key={resource.id}>
                                <Box
                                    sx={{
                                        p: 1,
                                        borderLeft: `2px solid ${colors.info}`,
                                        borderRadius: 2,
                                        background: `linear-gradient(90deg, ${colors.secondary}, ${colors.bgColor2})`,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 15,
                                            fontWeight: 'bold',
                                            color: colors.warning,
                                        }}
                                    >
                                        {resource.total}
                                        <sup
                                            style={{
                                                fontSize: 10,
                                                color: colors.success,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            TZS
                                        </sup>
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: 13,
                                            opacity: .9
                                        }}
                                    >
                                        {resource.name}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default RevenueCard;
