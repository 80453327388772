import React from 'react';
import { Badge, Box, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { filter } from 'lodash';
import { size } from 'lodash';
import { organizationsSelector } from '../../../states/features/selectors';
import { colors } from '../../../assets/utils/constants';
import { Link } from 'react-router-dom';

const regCompanyStats = (companies) => {
    const stats = [
        {
            label: 'On-progreess companies',
            total: size(filter(companies, ['is_verified', 'pending'])),
            color: colors.warning,
            url: 'on-progress',
        },
        {
            label: 'Verification Request',
            total: size(filter(companies, ['is_verified', 'on_progress'])),
            color: colors.info,
            url: 'request',
        },
        {
            label: 'Verified companies',
            total: size(filter(companies, ['is_verified', 'verified'])),
            color: colors.success,
            url: 'verified',
        },
        {
            label: 'Denied companies',
            total: size(filter(companies, ['is_verified', 'denied'])),
            color: colors.danger,
            url: 'denied',
        },
    ];

    return stats;
};

const CompanyStatusesInfoboxes = () => {
    // ############### REDUCER STATE #################
    const { organizations } = useSelector(organizationsSelector);
    const companies = filter(organizations, ['org_type', 'Company']);
    const stats = regCompanyStats(companies);

    return (
        <>
            <Box
                sx={{
                    borderBottomLeftRadius: 5,
                    borderBottomRightRadius: 5,
                    padding: 2,
                    borderBottom: `15px solid ${colors.secondary}`,
                    background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor2})`,
                }}
            >
                <Grid container spacing={2}>
                    {stats?.map((stat, index) => (
                        <Grid item sm={3} key={index}>
                            <Link to={stat.url}>
                                <Box
                                    sx={{
                                        'width': '100%',
                                        'border': `1px solid ${colors.bgColor5}`,
                                        'display': 'flex',
                                        'justifyContent': 'space-between',
                                        'paddingLeft': 1,
                                        'paddingRight': 3,
                                        'paddingTop': 1,
                                        'paddingBottom': 1,
                                        'borderRadius': 2,
                                        'color': colors.primary,
                                        'alignItems': 'center',
                                        'transition': `.3s all ease-in-out`,
                                        'borderLeft': `5px solid ${stat.color}`,
                                        ':hover': {
                                            transition: `.3s all ease-in-out`,
                                            background: `linear-gradient(180deg, ${colors.bgColor1}, ${colors.bgColor3})`,
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            fontWeight: 'bold',
                                            color: colors.primary,
                                            opacity: 0.8,
                                        }}
                                    >
                                        {stat.label}
                                    </Typography>
                                    <Typography>
                                        <Badge
                                            badgeContent={stat.total}
                                            showZero
                                            color="primary"
                                            sx={{ opacity: 0.8 }}
                                        />
                                    </Typography>
                                </Box>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </>
    );
};

export default CompanyStatusesInfoboxes;
